import { Form, Alert } from 'antd';
import {
  PureQueryOptions,
  useSuspenseQuery,
  useMutation,
  gql,
} from '@apollo/client';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  GetActivityForEditDocument,
  EditActivityDocument,
  SprintKaInput,
  ActivityFullForm_MitemFragment,
} from '../../../../generated/graphql';
import { standardDateFormat } from '../../../../services/dateFormats';
import { getUnique } from '../../../../services/getUnique';
import { RecursiveOmit } from '../../../../services/typeHelpers';
import {
  ActivityForm, // Change import to ActivityFormBase
  activityFormSchema,
  ValidatedActivityFormData,
} from './ActivityForm'; // Change path to ActivityFormBase

// Type definition now likely sourced from ActivityFormBase.tsx if exported there
export type ActivityFullFormValues = Partial<
  z.infer<typeof activityFormSchema>
>;

interface Props {
  refetchQueries?: Array<string | PureQueryOptions>;
  onSuccess?: () => void;
  teamId: string;
  activityId: string;
}

export const EditActivity = ({
  teamId,
  activityId,
  onSuccess,
  refetchQueries,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { data: activityData } = useSuspenseQuery(GetActivityForEditDocument, {
    variables: {
      teamId,
      activityId,
    },
  });

  const isCompleted = activityData.mitem.completed;

  // Map activity data to form values - remains mostly the same
  const { mapActivityToFormValues, initialEntities } = useMemo(() => {
    if (!activityData?.mitem)
      return { mapActivityToFormValues: {}, initialEntities: {} };

    const directlyAlignedInitiatives =
      activityData.mitem.supportsInitiativeLinks?.map((i) => i.data) ?? [];
    const milestonesInitiatives =
      activityData.mitem.supportsMilestoneLinks?.flatMap((m) =>
        m.data.metadata.supportsInitiatives.map((si) => si.data)
      ) ?? [];
    const uniqueInitiatives = getUnique(
      [...directlyAlignedInitiatives, ...milestonesInitiatives],
      (item) => item?.id // Add optional chaining for safety
    ).filter((item) => !!item); // Filter out potential null/undefined items

    return {
      mapActivityToFormValues: {
        name: activityData.mitem.name,
        definitionOfDone: activityData.mitem.definitionOfDone,
        deadline: activityData.mitem.deadline
          ? dayjs(activityData.mitem.deadline)
          : undefined,
        ownerId: activityData.mitem.owner2?.domainId.itemId,
        tags: activityData.mitem.tags?.map((t) => t.id) ?? [],
        alignments: [
          ...(activityData.mitem.supportsInitiativeLinks?.map((link) => ({
            initiativeId: link.id,
            milestoneId: undefined,
          })) ?? []),
          ...(activityData.mitem.supportsMilestoneLinks?.map((msLink) => ({
            initiativeId: msLink.data.metadata.supportsInitiatives[0]?.id,
            milestoneId: msLink.data.id,
          })) ?? []),
        ],
        supportMigId: activityData.mitem.supportedMigs?.[0]?.id,
      },
      initialEntities: {
        initiatives: uniqueInitiatives,
        milestones:
          activityData.mitem.supportsMilestoneLinks
            ?.map((m) => m.data)
            .filter((m) => !!m) ?? [], // Filter out potential null/undefined items
        owner: activityData.mitem.owner2,
        // Pass initial tags if TagsSelector needs it
        // tags: activityData.mitem.tags,
      },
    };
  }, [activityData]);

  const [editActivity, { loading: editPending }] = useMutation(
    EditActivityDocument,
    {
      onCompleted: () => {
        form.resetFields();
        onSuccess?.();
      },
    }
  );

  // Submit handler now receives validated data from ActivityForm
  const handleSubmit = (validatedData: ValidatedActivityFormData) => {
    const { alignments, tags, ...rest } = validatedData;

    const initiativeIds = alignments
      .filter((a) => a && !a.milestoneId && a.initiativeId) // Add null check
      .map((a) => a.initiativeId)
      .filter((id): id is string => typeof id === 'string'); // Type guard

    const milestoneIds = alignments
      .filter((a) => a && a.milestoneId) // Add null check
      .map((a) => a.milestoneId)
      .filter((id): id is string => typeof id === 'string'); // Type guard

    const skaToSubmit: SprintKaInput = isCompleted
      ? {
          supportsInitiative2Ids: initiativeIds,
          supportsMilestoneIds: milestoneIds,
          tags,
        }
      : {
          name: rest.name,
          definitionOfDone: rest.definitionOfDone,
          ownerId: rest.ownerId,
          tags,
          deadline: standardDateFormat(rest.deadline),
          supportsInitiative2Ids: initiativeIds,
          supportsMilestoneIds: milestoneIds,
          supportsMigIds: rest.supportMigId ? [{ id: rest.supportMigId }] : [],
        };

    editActivity({
      variables: {
        teamId,
        activityId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  return (
    // Use ActivityFormBase component
    <ActivityForm
      form={form}
      teamId={teamId}
      onSubmit={handleSubmit}
      initialValues={mapActivityToFormValues}
      isCompleted={isCompleted} // Pass completion status
      submitPending={editPending}
      submitButtonText={t('EditActivity.updateActivityButton')}
      topAlert={
        // Pass the alert as a prop
        isCompleted ? (
          <Alert
            className="mb"
            message={t('EditActivity.activityCompletedTitle')}
            description={t('EditActivity.activityCompletedDescription')}
            type="info"
            showIcon
          />
        ) : undefined
      }
      // Pass initial entity data needed by selects in edit mode
      alignedInitiatives={initialEntities?.initiatives}
      alignedMilestones={initialEntities?.milestones}
      initialOwner={initialEntities?.owner}
      // Pass initial tags if needed by TagsSelector
      // initialTags={initialEntities?.tags}
    />
  );
};

// Fragment remains the same, but ensure it's defined or imported if needed by mutation/query response
export type ActivityInitialValues = Partial<
  RecursiveOmit<ActivityFullForm_MitemFragment, '__typename'>
>;

export const EDIT_MITEM_V2 = gql`
  mutation editActivity(
    $teamId: ID!
    $activityId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $activityId
      sprintKaData: $sprintKeyActivity
    ) {
      ...EditActivityForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GET_ACTIVITY_FOR_EDIT = gql`
  query GetActivityForEdit($teamId: ID!, $activityId: ID!) {
    mitem(teamId: $teamId, mitemId: $activityId) {
      ...EditActivityForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment EditActivityForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              name
              description
              tag {
                title
                iconId
                colorCode
              }
              metadata {
                completedAt
                status
                archived
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
