import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  MarkMilestoneAsCompletedModalContent_MutationDocument,
  MarkMilestoneAsCompletedModalContent_QueryDocument,
} from '../../../../../../../../../generated/graphql';
import { howweErrorParser } from '../../../../../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../../../../../services/fetchNotificationProperties';
import { MarkAsCompleteIcon } from '../../../../../../../../../icons/MarkAsCompleteIcon';
import { Divider } from 'antd';
import { Btn } from '../../../../../../../../../components/Button';
import { MarkMilestoneAsCompletedModalContentSkeleton } from './MarkMilestoneAsCompletedModalContent.skeleton';

interface Props {
  milestoneId: string;
  onClose?: () => void;
}

export const MarkMilestoneAsCompletedModalContent = ({
  milestoneId,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    MarkMilestoneAsCompletedModalContent_QueryDocument,
    { variables: { milestoneId }, fetchPolicy: 'network-only' }
  );

  const [markAsCompleted, { loading }] = useMutation(
    MarkMilestoneAsCompletedModalContent_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('MarkMilestoneAsCompletedModalContent.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      onCompleted: () => {
        showNotification('success', {
          message: t('MarkMilestoneAsCompletedModalContent.saveSuccess'),
        });
        onClose?.();
      },
    }
  );

  const handleMarkAsCompleted = () => {
    markAsCompleted({
      variables: {
        milestoneId: milestoneId,
        milestone: {
          completed: true,
          rev: data.milestoneWithLinks.rev,
        },
      },
    });
  };

  return (
    <div className="text-c flx flx--column">
      <h3>{t('MarkMilestoneAsCompletedModalContent.title')}</h3>
      {data.milestoneWithLinks.name}
      <div>
        <MarkAsCompleteIcon className="txt--success" style={{ fontSize: 70 }} />
      </div>
      <Divider />
      <div>
        <Btn loading={loading} onClick={handleMarkAsCompleted} type="success">
          {t('MarkMilestoneAsCompletedModalContent.markAsCompleted')}
        </Btn>
      </div>
    </div>
  );
};

MarkMilestoneAsCompletedModalContent.Skeleton =
  MarkMilestoneAsCompletedModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE_FOR_MARK_AS_COMPLETE = gql`
  mutation markMilestoneAsCompletedModalContent_mutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestoneWithLinks(milestoneId: $milestoneId, milestone: $milestone) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONE_FOR_MARK_AS_COMPLETE = gql`
  query markMilestoneAsCompletedModalContent_query($milestoneId: ID!) {
    milestoneWithLinks(milestoneId: $milestoneId) {
      id
      name
      rev
      metadata {
        completedAt
      }
    }
  }
`;
