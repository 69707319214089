import { Spin } from 'antd';
import { Colors } from '../../appPages/componentLibrary/Colors';
import { CheckIcon } from '../../icons/CheckIcon';
import { UpcomingEventIcon } from '../../icons/UpcomingEventIcon';
import { WarningIcon } from '../../icons/WarningIcon';

export const ProgressStatsSkeleton = () => {
  return (
    <div className="flx">
      <div className=" mr flx flx--ai-center ">
        <CheckIcon className="txt--success" style={{ fontSize: 15 }} />
        <div className="font-size--lg txt--primary space--l">
          <Spin size="small" />
        </div>
      </div>
      <div className="mr flx flx--ai-center">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
        <div className="font-size--lg txt--primary space--l">
          <Spin size="small" />
        </div>
      </div>
      <div className="mr flx flx--ai-center txt--primary">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
        <div className="font-size--lg txt--primary space--l">
          <Spin size="small" />
        </div>
      </div>
    </div>
  );
};
