import { Form } from 'antd';
import {
  PureQueryOptions,
  useSuspenseQuery,
  useMutation,
  gql,
} from '@apollo/client';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateActivityFullVersionDocument, // Use the Create mutation
  SprintKaInput,
  GetActivityForCopyDocument,
} from '../../../../generated/graphql';
import { standardDateFormat } from '../../../../services/dateFormats';
import { getUnique } from '../../../../services/getUnique';
import {
  ActivityForm, // Change import to ActivityFormBase
  ValidatedActivityFormData,
} from './ActivityForm'; // Change path to ActivityFormBase

interface Props {
  refetchQueries?: Array<string | PureQueryOptions>;
  onSuccess?: () => void;
  teamId: string;
  activityIdToCopy: string; // ID of the activity to copy from
}

export const CopyActivity = ({
  teamId,
  activityIdToCopy,
  onSuccess,
  refetchQueries,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // Fetch data of the activity being copied
  const { data: activityData } = useSuspenseQuery(GetActivityForCopyDocument, {
    variables: {
      teamId,
      activityId: activityIdToCopy, // Use the ID to copy
    },
  });

  // Map data to initial values, similar to EditActivity
  const { mapActivityToFormValues, initialEntities } = useMemo(() => {
    if (!activityData?.mitem)
      return { mapActivityToFormValues: {}, initialEntities: {} };

    const directlyAlignedInitiatives =
      activityData.mitem.supportsInitiativeLinks?.map((i) => i.data) ?? [];
    const milestonesInitiatives =
      activityData.mitem.supportsMilestoneLinks?.flatMap((m) =>
        m.data.metadata.supportsInitiatives.map((si) => si.data)
      ) ?? [];
    const uniqueInitiatives = getUnique(
      [...directlyAlignedInitiatives, ...milestonesInitiatives],
      (item) => item?.id
    ).filter((item) => !!item);

    // Pre-populate form with data from the copied activity
    return {
      mapActivityToFormValues: {
        name: activityData.mitem.name,
        definitionOfDone: activityData.mitem.definitionOfDone,
        deadline: activityData.mitem.deadline
          ? dayjs(activityData.mitem.deadline)
          : undefined,
        ownerId: activityData.mitem.owner2?.domainId.itemId, // Copy owner
        tags: activityData.mitem.tags?.map((t) => t.id) ?? [], // Copy tags
        alignments: [
          ...(activityData.mitem.supportsInitiativeLinks?.map((link) => ({
            initiativeId: link.id,
            milestoneId: undefined,
          })) ?? []),
          ...(activityData.mitem.supportsMilestoneLinks?.map((msLink) => ({
            initiativeId: msLink.data.metadata.supportsInitiatives[0]?.id,
            milestoneId: msLink.data.id,
          })) ?? []),
        ],
        supportMigId: activityData.mitem.supportedMigs?.[0]?.id, // Copy MIG support
      },
      initialEntities: {
        // Needed for select dropdowns if they show disconnected items
        initiatives: uniqueInitiatives,
        milestones:
          activityData.mitem.supportsMilestoneLinks
            ?.map((m) => m.data)
            .filter((m) => !!m) ?? [],
        owner: activityData.mitem.owner2,
      },
    };
  }, [activityData]);

  // Use the CREATE mutation
  const [createActivity, { loading: createPending }] = useMutation(
    CreateActivityFullVersionDocument,
    {
      update(cache, { data }) {
        // Same cache update logic as Create
        if (!data?.createSprintKeyActivity) return;
        cache.modify({
          id: `TeamActivitiesResponse:${teamId}`,
          fields: {
            sprintKeyActivities(existingActivities = []) {
              const newActivityRef = cache.writeFragment({
                data: data.createSprintKeyActivity,
                fragment: gql`
                  fragment NewCopiedActivity on Mitem {
                    id
                  }
                `,
              });
              return [...existingActivities, newActivityRef];
            },
          },
        });
      },
      onCompleted: () => {
        form.resetFields();
        onSuccess?.(); // Close modal on success
      },
    }
  );

  // Submit handler uses the CREATE mutation
  const handleSubmit = (validatedData: ValidatedActivityFormData) => {
    const { alignments, tags, ...rest } = validatedData;

    const initiativeIds = alignments
      .filter((a) => a && !a.milestoneId && a.initiativeId)
      .map((a) => a.initiativeId)
      .filter((id): id is string => typeof id === 'string');

    const milestoneIds = alignments
      .filter((a) => a && a.milestoneId)
      .map((a) => a.milestoneId)
      .filter((id): id is string => typeof id === 'string');

    const skaToSubmit: SprintKaInput = {
      name: rest.name,
      definitionOfDone: rest.definitionOfDone,
      ownerId: rest.ownerId,
      tags,
      deadline: standardDateFormat(rest.deadline),
      supportsInitiative2Ids: initiativeIds,
      supportsMilestoneIds: milestoneIds,
      supportsMigIds: rest.supportMigId ? [{ id: rest.supportMigId }] : [],
    };

    createActivity({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  return (
    <ActivityForm
      form={form}
      teamId={teamId}
      onSubmit={handleSubmit}
      initialValues={mapActivityToFormValues} // Use mapped values from copied activity
      isCompleted={false} // New activity is not completed
      submitPending={createPending}
      submitButtonText={t('common.copy')} // Use correct translation key
      // Pass initial entity data needed by selects if they show disconnected items
      alignedInitiatives={initialEntities?.initiatives}
      alignedMilestones={initialEntities?.milestones}
      initialOwner={initialEntities?.owner}
    />
  );
};

// Removed commented-out fragment causing GraphQL error

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GET_ACTIVITY_FOR_COPY = gql`
  query GetActivityForCopy($teamId: ID!, $activityId: ID!) {
    mitem(teamId: $teamId, mitemId: $activityId) {
      ...CopyActivityForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_TO_COPY_MITEM = gql`
  fragment CopyActivityForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              name
              description
              tag {
                title
                iconId
                colorCode
              }
              metadata {
                completedAt
                status
                archived
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
