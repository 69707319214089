import { gql, useMutation } from '@apollo/client';
import { Form, Input, Switch, Typography } from 'antd';
import { showNotification } from '../../services/fetchNotificationProperties';
import { TitleWithTooltip } from '../../appPages/team/setup/components/TitleWithTooltip';
import { Btn } from '../Button';
import { ErrorAlert } from '../ErrorAlert';
import { useTranslation } from 'react-i18next';
import {
  GetMitemEventTimelineDocument,
  MitemStatus,
  ReportDrawer2Document,
  ReportKeyActivityAchievementDocument,
  SprintKeyActivityDetails_QueryDocument,
  SprintKeyActivityReportForm__MitemFragment,
} from '../../generated/graphql';
import { DisplayDate } from '../DisplayDate';

interface Props {
  keyActivity: SprintKeyActivityReportForm__MitemFragment;
  onCompleted?: () => void;
}

export const SprintKeyActivityReportForm = ({
  keyActivity,
  onCompleted,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const reportAsComplete = Form.useWatch('completed', form);

  const [reportAchievement, { error, loading }] = useMutation(
    ReportKeyActivityAchievementDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: t('SprintKeyActivityReportForm.success'),
          duration: 15,
        });

        (window as any).Intercom?.('update', {
          last_reported_sprint_key_activity_at: new Date(),
          last_reported_key_activity_at: new Date(),
        });
        form.resetFields();
        onCompleted?.();
      },
      refetchQueries: () => [
        {
          query: SprintKeyActivityDetails_QueryDocument,
          variables: { skaId: keyActivity.id, teamId: keyActivity.teamId },
        },
        {
          query: GetMitemEventTimelineDocument,
          variables: { teamId: keyActivity.teamId, mitemId: keyActivity.id },
        },
        {
          query: ReportDrawer2Document,
        },
      ],
    }
  );

  const isCompleted = keyActivity.status === MitemStatus.COMPLETED;

  return (
    <div>
      <Typography.Title type="secondary" level={5} className="mb--none">
        {t('common.title')}
      </Typography.Title>
      <Typography.Text>{keyActivity.name}</Typography.Text>
      <Typography.Title type="secondary" level={5} className="mb--none">
        {t('common.deadline')}
      </Typography.Title>
      <Typography.Text>
        <DisplayDate date={keyActivity.deadline} alwaysIncludeYear />
      </Typography.Text>

      <Typography.Title type="secondary" level={5} className="mb--none">
        {t('common.definitionOfDone')}
      </Typography.Title>

      <Typography.Paragraph
        ellipsis={{ rows: 2, expandable: true }}
        className="mb--xl preserveLinebreaks"
      >
        {keyActivity.definitionOfDone}
      </Typography.Paragraph>

      <Form
        form={form}
        layout="vertical"
        className="mt--xs"
        requiredMark={false}
        onFinish={(values) => {
          reportAchievement({
            variables: {
              teamId: keyActivity.teamId,
              mitemId: keyActivity.id,
              mitemAchievement: {
                completed: values.completed ?? true,
                description: values.achievementDescription,
              },
            },
          });
        }}
      >
        <Form.Item
          name="achievementDescription"
          label={
            <Typography.Title type="secondary" level={5} className="mb--none">
              <TitleWithTooltip
                title={t(
                  'SprintKeyActivityReportForm.achievementDescription.label'
                )}
                tooltipContent={t(
                  'SprintKeyActivityReportForm.achievementDescription.hint'
                )}
              />
            </Typography.Title>
          }
          rules={[
            {
              max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
              message: t(
                'SprintKeyActivityReportForm.achievementDescription.maxLength',
                {
                  max: ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
                }
              ),
            },
          ]}
        >
          <Input.TextArea
            disabled={isCompleted}
            autoComplete="off"
            rows={5}
            showCount
            maxLength={ACHIEVEMENT_DESCRIPTION_MAX_LENGTH}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Title type="secondary" level={5} className="mb--none">
              <TitleWithTooltip
                title={t('SprintKeyActivityReportForm.mitemStatus')}
                tooltipContent={t(
                  'SprintKeyActivityReportForm.mitemStatusTooltip'
                )}
              />
            </Typography.Title>
          }
        >
          <Form.Item
            name="completed"
            initialValue={false}
            valuePropName="checked"
            noStyle
          >
            <Switch size="small" disabled={isCompleted} />
          </Form.Item>
          <span className="ml">
            {t('common.sprintKeyActivity.markAsCompleted')}
          </span>
        </Form.Item>

        <Btn
          type="callToAction"
          loading={loading}
          disabled={isCompleted}
          htmlType="submit"
          data-intercom-target="Report Sprint Key Activity Submit Button"
        >
          {reportAsComplete
            ? t('SprintKeyActivityReportForm.reportComplete')
            : t('SprintKeyActivityReportForm.reportProgress')}
        </Btn>

        <ErrorAlert error={error} />
      </Form>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment SprintKeyActivityReportForm__Mitem on Mitem {
    id
    name
    teamId
    status
    deadline
    definitionOfDone
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REPORT_MITEM_ACHIEVEMENT2 = gql`
  mutation reportKeyActivityAchievement(
    $teamId: ID!
    $mitemId: ID!
    $mitemAchievement: CreateMitemAchievementInput!
  ) {
    createMitemAchievement(
      teamId: $teamId
      mitemId: $mitemId
      mitemAchievement: $mitemAchievement
    ) {
      id
    }
  }
`;

const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 1000;
