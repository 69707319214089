import TextArea from 'antd/es/input/TextArea';
import { useAccelerationMeeting } from '../../../../AccelerationMeetingProvider';
import { useTranslation } from 'react-i18next';
import { MessageOutlined } from '@ant-design/icons';

interface Props {
  activityId: string;
  userId: string;
}

export const CommitmentNote = ({ activityId, userId }: Props) => {
  const { t } = useTranslation();
  const { sprintKeyActivityContext } = useAccelerationMeeting();

  const usersCommitments =
    sprintKeyActivityContext.getUsersCommitments(activityId);
  const ownerCommitment = usersCommitments?.commitment;

  return (
    <div className="flx mb">
      <MessageOutlined className="mr font-size--default" />
      <TextArea
        rows={1}
        placeholder={t('CommitmentNote.placeholder')}
        onBlur={(e) => {
          sprintKeyActivityContext.setUserCommitment(activityId, {
            ...ownerCommitment,
            note: e.target.value,
            userId: userId,
          });
        }}
      />
    </div>
  );
};
