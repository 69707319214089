import { Colors } from '../../appPages/componentLibrary/Colors';
import { CheckIcon } from '../../icons/CheckIcon';
import { UpcomingEventIcon } from '../../icons/UpcomingEventIcon';
import { WarningIcon } from '../../icons/WarningIcon';
import { ProgressStatsSkeleton } from './ProgressStats.Skeleton';

interface Props {
  fontSize?: number;
  stats: {
    completed: number;
    overdue: number;
    upcoming: number;
  };
}

export const ProgressStats = ({ stats, fontSize = 16 }: Props) => {
  return (
    <div className="flx" style={{ fontSize }}>
      <div className=" mr flx flx--ai-center ">
        <CheckIcon className="txt--success" />
        <div className="txt--primary space--l">{stats.completed}</div>
      </div>
      <div className="mr flx flx--ai-center">
        <WarningIcon
          style={{ color: Colors.Status.OVERDUE }}
          className="space--r"
        />
        <div className="txt--primary space--l">{stats.overdue}</div>
      </div>
      <div className="mr flx flx--ai-center txt--primary">
        <UpcomingEventIcon
          style={{ color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
        <div className="txt--primary space--l">{stats.upcoming}</div>
      </div>
    </div>
  );
};

ProgressStats.Skeleton = ProgressStatsSkeleton;
