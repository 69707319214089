import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  Suspense,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom'; // Import router hooks
import { CreateMitemDrawer } from './appPages/team/sprint/planning/components/CreateMitemDrawer';
import { EditSprintKeyActivityDrawer } from './appPages/team/sprint/planning/components/mitemListEntry/EditSprintKeyActivityDrawer';
import { EditSprintKeyActivityDrawer__MitemFragment } from './generated/graphql';
import { PureQueryOptions } from '@apollo/client';
import { CreateActivityModal } from './components/activities/modals/CreateActivityModal';
import { ActivityFullFormValues } from './components/activities/modals/forms/CreateActivityFullVersion';
import { SimpleActivityFormValues } from './components/activities/modals/forms/CreateActivitySimpleVersion';
import { EditActivityModal } from './components/activities/modals/EditActivityModal';
import { Modal } from 'antd';
import {
  ActivityDetails,
  SprintKeyActivityDetailsWrapper,
} from './components/activities/ActivityDetails';

type ActivityDetailParams = {
  activityId: string;
  teamId: string;
  activeTab?: 'details' | 'history' | 'report';
};

type ModalType =
  | {
      type: 'edit';
      prefilledValues?: any;
      activity: EditSprintKeyActivityDrawer__MitemFragment;
    }
  | {
      type: 'create';
      teamId: string;
      activityId?: string;
      prefilledValues?: any;
    }
  | {
      type: 'createActivity';
      formType?:
        | {
            type: 'simple';
            prefilledValues?: SimpleActivityFormValues;
          }
        | {
            type: 'full';
            prefilledValues?: ActivityFullFormValues;
          }
        | {
            type: 'copy';
            activityIdToCopyFrom: string;
          };
      teamId: string;
      activityId?: string;
      refetchQueries?: Array<string | PureQueryOptions>;
    }
  | {
      type: 'editActivity';
      teamId: string;
      activityId: string;
      refetchQueries?: Array<string | PureQueryOptions>;
    }
  | {
      type: 'activityDetails'; // Add type for URL-driven modal
      urlBased?: boolean; // Indicates if the modal is opened via URL
      params: ActivityDetailParams;
    };

interface ModalContextType {
  openModal: (modal: ModalType) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalState, setModalState] = useState<ModalType | null>(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const modalType = params.get('modalType');

    if (modalType === 'activityDetails') {
      // URL indicates modal should be open
      const activityId = params.get('activityId');
      const teamId = params.get('teamId');
      const activeTab =
        (params.get('activeTab') as ActivityDetailParams['activeTab']) ??
        'details';
      if (teamId && activityId) {
        // Update state if it's not already matching the URL
        setModalState({
          type: 'activityDetails',
          params: { activityId, teamId, activeTab },
        });
      }
    }
  }, [location.search]); // Rerun effect when URL search string changes

  const closeModal = useCallback(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('modalType') && modalState && 'params' in modalState) {
      params.delete('modalType');
      Object.entries(modalState.params || {}).forEach(([key]) => {
        params.delete(key);
      });
      history.push(`${location.pathname}?${params.toString()}`);
    }
    setModalState(null);
  }, [history, location, modalState]);

  const openModal = useCallback(
    (modal: ModalType) => {
      closeModal();
      if ('urlBased' in modal && modal.urlBased) {
        // Handle URL-driven modal opening
        const params = new URLSearchParams(location.search);
        params.set('modalType', modal.type);
        Object.entries(modal.params).forEach(([key, value]) => {
          if (value !== undefined) {
            params.set(key, value);
          }
        });
        history.push(`${location.pathname}?${params.toString()}`);
      } else {
        // Handle state-driven modals
        setModalState(modal);
      }
    },
    [history, location, closeModal]
  );

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {modalState?.type === 'create' && (
        <CreateMitemDrawer
          teamId={modalState.teamId}
          showHelpLinks={false}
          showModal={true}
          onCancel={closeModal}
          prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
      {modalState?.type === 'createActivity' && (
        <CreateActivityModal
          teamId={modalState.teamId}
          open={true}
          onCancel={closeModal}
          formType={modalState.formType}
          refetchQueries={modalState.refetchQueries}
          onComplete={closeModal}
        />
      )}
      {modalState?.type === 'editActivity' && (
        <EditActivityModal
          teamId={modalState.teamId}
          activityId={modalState.activityId}
          open={true}
          onCancel={closeModal}
          refetchQueries={modalState.refetchQueries}
          onComplete={closeModal}
        />
      )}

      {modalState?.type === 'edit' && (
        <EditSprintKeyActivityDrawer
          mitem={modalState.activity}
          showModal={true}
          onCancel={closeModal}
          //prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
      {modalState?.type === 'activityDetails' && (
        <Modal
          open={true}
          zIndex={1100}
          onCancel={closeModal}
          footer={null}
          modalRender={() => (
            <Suspense
              fallback={<ActivityDetails.skeleton onClose={closeModal} />}
            >
              <SprintKeyActivityDetailsWrapper
                skaId={modalState.params.activityId}
                teamId={modalState.params.teamId}
                activeTab={modalState.params.activeTab}
                onClose={closeModal}
              />
            </Suspense>
          )}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
