import './MilestoneActivitiesTable.less';
import {
  GetSprintKeyActivitiesForMilestoneDocument,
  MilestoneActivityTable__MitemFragment,
} from '../../../../../../../../generated/graphql';
import { MilestoneActivityTableRow } from './MilestoneActivityTableRow';
import { useTranslation } from 'react-i18next';
import { gql, useSuspenseQuery } from '@apollo/client';
import { stringSort } from '../../../../../../../../services/stringSort';
import { MilestoneActivitiesTableSkeleton } from './MilestoneActivitiesTable.skeleton';
import { Empty } from 'antd';
import { MilestoneActivitiesTableError } from './MilestoneActivitiesTable.Error2';
// Remove old hook import
import { useModal } from '../../../../../../../../ModalProvider'; // Import useModal

interface Props {
  milestoneItemId: string;
  filterPredicate?: (params: MilestoneActivityTable__MitemFragment) => boolean;
}

export const MilestoneActivitiesTable = ({
  milestoneItemId,
  filterPredicate,
}: Props) => {
  const { openModal } = useModal(); // Use the modal context hook
  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesForMilestoneDocument,
    {
      variables: { milestoneId: milestoneItemId },
      fetchPolicy: 'network-only',
    }
  );
  const { t } = useTranslation();

  const sortedKa =
    data.sprintKeyActivitiesForMilestone.sprintKeyActivities.toSorted((a, b) =>
      stringSort(a.deadline, b.deadline)
    );

  const filteredActivities = filterPredicate
    ? sortedKa.filter(filterPredicate)
    : sortedKa;

  return (
    <div className="MilestoneActivitiesTable">
      <table className="MilestoneActivitiesTable">
        <thead>
          <tr>
            <th className="text-l txt--secondary">{t('common.deadline')}</th>
            <th className="text-l txt--secondary">{t('common.activity')}</th>
            <th className="text-l txt--secondary">{t('common.team')}</th>
            <th className="text-c txt--secondary">{t('common.status')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredActivities.map((ska) => (
            <MilestoneActivityTableRow
              key={ska.id}
              keyActivity={ska}
              // Pass the openModal function from the context, matching the expected signature
              onSelectedSka={(activityId: string, teamId: string) =>
                openModal({
                  type: 'activityDetails',
                  urlBased: true,
                  params: {
                    activityId,
                    teamId,
                    activeTab: 'details',
                  },
                })
              }
            />
          ))}
          {filteredActivities.length === 0 && (
            <tr className="text-c" style={{ height: 120 }}>
              <td colSpan={5}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className="ma"
                  description={t('MilestoneActivitiesTable.noData')}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

MilestoneActivitiesTable.Skeleton = MilestoneActivitiesTableSkeleton;
MilestoneActivitiesTable.Error = MilestoneActivitiesTableError;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_MILESTONES = gql`
  query getSprintKeyActivitiesForMilestone($tenantId: ID, $milestoneId: ID!) {
    sprintKeyActivitiesForMilestone(
      tenantId: $tenantId
      milestoneId: $milestoneId
    ) {
      sprintKeyActivities {
        id
        ...MilestoneActivityTable__Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_ACTIVITY_TABLE__MITEM = gql`
  fragment MilestoneActivityTable__Mitem on Mitem {
    id
    teamId
    ...MilestoneActivityTableRow__Mitem
    ...SprintKeyActivityDetails_Mitem
  }
`;
