import { CheckCircleOutlined } from '@ant-design/icons';
import { Divider, Timeline, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { friendlyDate } from '../../../../../../../../../../services/dateFormats';
import { mitemStatusColor } from '../../../../../../../../../../styleVars';
import { KeyActivitySection } from '../../common/KeyActivitySection';
import './WeeklyKeyActivityUserSection.less';
import { AvatarColumn } from '../../common/AvatarColumn';
import dayjs from 'dayjs';
import { WkaProgressIcon } from '../../../../../../../../../../icons/WkaProgressIcon';

interface Props {
  user: {
    id: string;
    name: string | null;
    displayName: string | null;
    email: string;
    initials: string;
  };
  filteredWeeklyKeyActivities: {
    id: string;
    name: string;
    note: string | null;
    quantity: number | null;
    unit: string | null;
    achievements: {
      id: string;
      quantity: number | null;
      description: string | null;
      achieved: string;
    }[];
  }[];

  expandReports: boolean;
}

const getPerformanceClass = (committed: number, achieved: number) => {
  if (achieved === 0) return 'noAchievements';
  if (achieved >= committed) return 'completed';
  return 'inProgress';
};

export const WeeklyKeyActivityUserSection = ({
  user,
  filteredWeeklyKeyActivities,
  expandReports,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flx">
      <AvatarColumn user={user} />
      <div style={{ width: '100%' }}>
        {filteredWeeklyKeyActivities.map((wka, index) => {
          const committed = wka.quantity ?? 0;
          const achieved = wka.achievements.reduce(
            (partialSum, a) => partialSum + (a.quantity ?? 0),
            0
          );
          const performanceClass = getPerformanceClass(committed, achieved);
          return (
            <div key={wka.id}>
              <KeyActivitySection
                title={wka.name}
                note={wka.note}
                numberOfReports={wka.achievements.length}
                status={
                  <div className="flx">
                    <WkaProgressIcon
                      className={`WeeklyKeyActivityUserSection__icon ${performanceClass}`}
                    />
                    <div
                      className={`WeeklyKeyActivityUserSection__performance ${performanceClass}`}
                    >
                      <Tooltip
                        placement="topRight"
                        title={
                          <div>
                            <div>
                              {t('WeeklyKeyActivitySection.achieved', {
                                achieved,
                              })}
                            </div>
                            <div>
                              {t('WeeklyKeyActivitySection.committed', {
                                committed,
                              })}{' '}
                            </div>
                          </div>
                        }
                      >
                        {achieved} / {committed}
                      </Tooltip>
                    </div>
                  </div>
                }
                expandReports={expandReports}
              >
                <Timeline className="mt--xl">
                  {wka.achievements.map((achievment, index) => (
                    <Timeline.Item
                      key={`${index}`}
                      dot={
                        <div style={{ color: mitemStatusColor.COMPLETED }}>
                          <CheckCircleOutlined />
                        </div>
                      }
                    >
                      <>
                        <div className="flx flx--jc-space-between">
                          <Typography.Text strong>
                            {t('WeeklyKeyActivitySection.reported', {
                              unit: wka.unit,
                              quantity: achievment.quantity,
                            })}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            <span title={friendlyDate(achievment.achieved)}>
                              {dayjs(achievment.achieved).fromNow()}
                            </span>
                          </Typography.Text>
                        </div>
                        {achievment.description ? (
                          <Typography.Text type="secondary">
                            {achievment.description}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="secondary" className="italic">
                            {t('WeeklyKeyActivitySection.noComment')}
                          </Typography.Text>
                        )}
                      </>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </KeyActivitySection>

              {index !== filteredWeeklyKeyActivities.length - 1 && (
                <Divider className="mt--s mb--s" />
              )}
            </div>
          );
        })}

        {filteredWeeklyKeyActivities.length === 0 && (
          <Typography.Text type="secondary" className="center-content italic">
            {t('WeeklyKeyActivityUserSection.noResults')}
          </Typography.Text>
        )}
      </div>
    </div>
  );
};
