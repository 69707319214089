import './PerformanceTag.less';
import { roundToFloor } from '../services/roundNumbersHelper';
import { WarningIcon } from '../icons/WarningIcon';
import { ErrorIcon } from '../icons/ErrorIcon';
import './PerformanceTag.less';
import { statusColorHelper } from '../services/statusColorHelper';
import { ThumbsUpIcon } from '../icons/ThumbsUpIcon';

interface Props {
  value?: number | null;
  loading?: boolean;
  className?: string;
}

export const PerformanceTag = ({ value, loading, className = '' }: Props) => {
  const roundedValue = roundToFloor(value);

  const displayValue =
    roundedValue != null ? (
      `${roundedValue}%`
    ) : loading ? (
      <span>&nbsp;</span> // use NonBreakingSpace to prevent height collapse when missing value
    ) : (
      <span>N/A</span>
    );

  let icon;

  if (roundedValue != null) {
    if (roundedValue >= 100) {
      icon = <ThumbsUpIcon className="mr--xs" />;
    } else if (roundedValue >= 80) {
      icon = <WarningIcon className="mr--xs" />;
    } else if (roundedValue < 80) {
      icon = <ErrorIcon className="mr--xs" />;
    }
  }

  return (
    <div style={{ color: statusColorHelper(value) }} className={className}>
      {icon}
      {displayValue}
    </div>
  );
};
