import { Modal } from 'antd';
import { CreateActivitySimpleVersion } from './forms/CreateActivitySimpleVersion';
import { PureQueryOptions } from '@apollo/client';
import {
  ActivityFullFormValues,
  CreateActivityFullVersion,
} from './forms/CreateActivityFullVersion';
import { CopyActivity } from './forms/CopyActivity';
import { ActivityFormSkeleton } from '../ActivityFormSkeleton';
import { Suspense } from 'react';

interface Props {
  open: boolean;
  teamId: string;
  refetchQueries?: Array<string | PureQueryOptions>;
  formType?:
    | {
        type: 'simple';
        prefilledValues?: any;
      }
    | {
        type: 'full';
        prefilledValues?: ActivityFullFormValues;
      }
    | {
        type: 'copy';
        activityIdToCopyFrom: string;
      };
  onCancel: () => void;
  onComplete?: () => void;
}

export const CreateActivityModal = ({
  open,
  teamId,
  refetchQueries,
  formType,
  onCancel,
  onComplete,
}: Props) => {
  const renderForm = () => {
    if (!formType) return null;

    switch (formType.type) {
      case 'simple':
        return (
          <CreateActivitySimpleVersion
            teamId={teamId}
            onSuccess={onComplete}
            initialValues={formType.prefilledValues}
            refetchQueries={refetchQueries}
          />
        );
      case 'full':
        return (
          <CreateActivityFullVersion
            teamId={teamId}
            onSuccess={onComplete}
            initialValues={formType.prefilledValues}
            refetchQueries={refetchQueries}
          />
        );
      case 'copy':
        return (
          <Suspense fallback={<ActivityFormSkeleton />}>
            <CopyActivity
              teamId={teamId}
              activityIdToCopy={formType.activityIdToCopyFrom}
              onSuccess={onComplete}
              refetchQueries={refetchQueries}
            />
          </Suspense>
        );

      default:
        return null;
    }
  };

  const getModalWidth = () => {
    if (!formType) return 500;

    switch (formType.type) {
      case 'full':
      case 'copy':
        return 650;
      case 'simple':
        return 500;
      default:
        return 500;
    }
  };

  return (
    <Modal
      open={open}
      title={
        <h3 className="mb--l">
          {formType?.type === 'copy' ? 'Copy Activity' : 'Create Activity'}
        </h3>
      }
      width={getModalWidth()}
      footer={null}
      onCancel={onCancel}
    >
      {renderForm()}
    </Modal>
  );
};
