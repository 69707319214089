import { Suspense, useState } from 'react'; // Import Suspense
import { Btn } from '../../../../../../../../components/Button';

import { ActivityCommitmentStepSkeleton } from './ActivityCommitmentStep.Skeleton';
import { MonthBasedCommitments } from './components/MonthBasedCommitments';
import { OwnerBasedCommitments } from './components/OwnerBasedCommitments';
import { InitiativeBasedCommitments } from './components/InitiativeBasedCommitments'; // Import the new component

interface Props {
  teamId: string;
}

export const ActivityCommitmentStep = ({ teamId }: Props) => {
  const [view, setView] = useState<View>(getStoredView());

  // Update handler that also persists to localStorage
  const handleViewChange = (newView: View) => {
    setView(newView);
    saveViewToStorage(newView);
  };

  return (
    <div className="mr--xxl ml--xxl">
      <h2>Commitments</h2>
      <div className="flx flx--ai-center flx--gap--s mt--l mb--xl pb--l borderBottom borderTop pt--l">
        <span>Activities grouped by:</span>
        <Btn
          shape="round"
          onClick={() => handleViewChange('Month')}
          type={view === 'Month' ? 'primary' : 'default'}
        >
          Month
        </Btn>
        <Btn
          shape="round"
          onClick={() => handleViewChange('Owner')}
          type={view === 'Owner' ? 'primary' : 'default'}
        >
          Owner
        </Btn>
        <Btn
          shape="round"
          onClick={() => handleViewChange('Initiatives')}
          type={view === 'Initiatives' ? 'primary' : 'default'}
          // Remove disabled and title
        >
          Initiatives
        </Btn>
      </div>

      {/* Conditionally render based on view state */}
      {/* Wrap in Suspense for potential loading states within components */}
      <Suspense fallback={<ActivityCommitmentStepSkeleton />}>
        {view === 'Month' && <MonthBasedCommitments teamId={teamId} />}
        {view === 'Owner' && <OwnerBasedCommitments teamId={teamId} />}
        {/* Render the new component for Initiatives view */}
        {view === 'Initiatives' && (
          <InitiativeBasedCommitments teamId={teamId} />
        )}
      </Suspense>
    </div>
  );
};
ActivityCommitmentStep.Skeleton = ActivityCommitmentStepSkeleton;

type View = 'Month' | 'Owner' | 'Initiatives';

const LOCAL_STORAGE_VIEW_KEY = 'commitmentViewType';

// Helper function to get stored view from localStorage
const getStoredView = (): View => {
  const storedView = localStorage.getItem(LOCAL_STORAGE_VIEW_KEY);
  return (storedView as View) || 'Month';
};

// Helper function to save view to localStorage
const saveViewToStorage = (view: View): void => {
  localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, view);
};
