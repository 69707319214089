import { Form, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { gql, PureQueryOptions, useMutation } from '@apollo/client';
import {
  ActivityFullForm_MitemFragment,
  CreateActivityFullVersionDocument,
  SprintKaInput,
} from '../../../../generated/graphql';
import { RecursiveOmit } from '../../../../services/typeHelpers';
import { standardDateFormat } from '../../../../services/dateFormats';
import dayjs from 'dayjs';
import { useState } from 'react';
import { z } from 'zod';
import {
  ActivityForm, // Change import to ActivityFormBase
  activityFormSchema,
  ValidatedActivityFormData,
} from './ActivityForm'; // Change path to ActivityFormBase

interface Props {
  initialValues?: ActivityFullFormValues;
  refetchQueries?: Array<string | PureQueryOptions>;
  onSuccess?: () => void;
  teamId: string;
}

// Type definition now likely sourced from ActivityForm.tsx if exported there,
// but keeping it here for now if it's used elsewhere.
export type ActivityFullFormValues = Partial<
  z.infer<typeof activityFormSchema>
>;

// Helper function remains the same
const getInitialFormValues = (values?: ActivityFullFormValues) => {
  return {
    name: values?.name,
    definitionOfDone: values?.definitionOfDone,
    supportMigId: values?.supportMigId,
    deadline: values?.deadline && dayjs(values.deadline),
    ownerId: values?.ownerId,
    tags: values?.tags ?? [],
    alignments: values?.alignments ?? [{}], // Default with one empty alignment row
  };
};

export const CreateActivityFullVersion = ({
  teamId,
  initialValues,
  onSuccess,
  refetchQueries,
}: Props) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [createActivityFullVersion, { loading: createPending }] = useMutation(
    CreateActivityFullVersionDocument,
    {
      update(cache, { data }) {
        if (!data?.createSprintKeyActivity) return;

        cache.modify({
          id: `TeamActivitiesResponse:${teamId}`,
          fields: {
            sprintKeyActivities(existingActivities = []) {
              const newActivityRef = cache.writeFragment({
                data: data.createSprintKeyActivity,
                fragment: gql`
                  fragment NewActivityFull on Mitem {
                    id
                  }
                `,
              });
              return [...existingActivities, newActivityRef];
            },
          },
        });
      },
      onCompleted: () => {
        form.resetFields();
        if (!keepOpen) {
          onSuccess?.();
        }
      },
    }
  );

  // Submit handler now receives validated data from ActivityForm
  const handleSubmit = (validatedData: ValidatedActivityFormData) => {
    const { alignments, ...rest } = validatedData;

    const initiativeIds = alignments
      .filter((a) => a && !a.milestoneId && a.initiativeId) // Add null check for a
      .map((a) => a.initiativeId)
      .filter((id): id is string => typeof id === 'string'); // Type guard

    const milestoneIds = alignments
      .filter((a) => a && a.milestoneId) // Add null check for a
      .map((a) => a.milestoneId)
      .filter((id): id is string => typeof id === 'string'); // Type guard

    const skaToSubmit: SprintKaInput = {
      name: rest.name,
      definitionOfDone: rest.definitionOfDone,
      ownerId: rest.ownerId,
      tags: rest.tags,
      deadline: standardDateFormat(rest.deadline),
      supportsInitiative2Ids: initiativeIds,
      supportsMilestoneIds: milestoneIds,
      supportsMigIds: rest.supportMigId ? [{ id: rest.supportMigId }] : [],
    };

    createActivityFullVersion({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  return (
    // Use ActivityFormBase component
    <ActivityForm
      form={form}
      teamId={teamId}
      onSubmit={handleSubmit}
      initialValues={getInitialFormValues(initialValues)}
      submitPending={createPending}
      submitButtonText={t('common.create')}
      extraActions={
        <Checkbox
          checked={keepOpen}
          onChange={(e) => setKeepOpen(e.target.checked)}
        >
          {t('common.keepOpen')}
        </Checkbox>
      }
    />
  );
};

// Fragment remains the same, but ensure it's defined or imported if needed by mutation response
export type ActivityInitialValues = Partial<
  RecursiveOmit<ActivityFullForm_MitemFragment, '__typename'>
>;

export const CREATE_MITEM_V2 = gql`
  mutation createActivityFullVersion(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...ActivityFullForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment ActivityFullForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
              tag {
                title
                iconId
                colorCode
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
