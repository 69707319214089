import Skeleton from 'react-loading-skeleton';
import { ActivityTable } from '../../../../../../activities/listPage/ActivityTable/ActivityTable';

export const ActivityCommitmentStepSkeleton = () => {
  return (
    <div>
      <div className="mt--xl flx">
        <div className="flx--1">
          <h3>
            <Skeleton width={150} />
          </h3>

          <>
            <ActivityTable.Skeleton />
            <ActivityTable.Skeleton />
          </>
        </div>
      </div>
    </div>
  );
};
