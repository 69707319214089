import { match } from 'ts-pattern';
import {
  MilestoneActivityTableRow__MitemFragment,
  MitemStatus,
} from '../../../../../../../../generated/graphql';
import { ManageSearchIcon } from '../../../../../../../../icons/ManageSearch';
import { UpcomingEventIcon } from '../../../../../../../../icons/UpcomingEventIcon';
import { friendlyDate } from '../../../../../../../../services/dateFormats';
import { Colors } from '../../../../../../../componentLibrary/Colors';
import { WarningIcon } from '../../../../../../../../icons/WarningIcon';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { gql } from '@apollo/client';

interface Props {
  keyActivity: MilestoneActivityTableRow__MitemFragment;
  onSelectedSka: (activityId: string, teamId: string) => void;
}

export const MilestoneActivityTableRow = ({
  keyActivity,
  onSelectedSka,
}: Props) => {
  const statusIcon = match(keyActivity)
    .with(
      { status: MitemStatus.OVERDUE },
      { status: MitemStatus.NOT_COMPLETED },
      () => (
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      )
    )
    .with({ status: MitemStatus.COMPLETED }, () => (
      <CheckIcon className="txt--success" style={{ fontSize: 15 }} />
    ))
    .with({ status: MitemStatus.PLANNED }, () => (
      <UpcomingEventIcon
        style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
        className="space--r"
      />
    ))
    .with({ status: MitemStatus.ACTIVE }, () => (
      <UpcomingEventIcon
        style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
        className="space--r"
      />
    ))
    .otherwise(() => '-');
  return (
    <tr>
      <td>{friendlyDate(keyActivity.deadline)}</td>
      <td>{keyActivity.name}</td>
      <td>{keyActivity.team2.name}</td>
      <td className="text-c">{statusIcon}</td>
      <td className="text-c">
        <ManageSearchIcon
          style={{ fontSize: 20, color: Colors.Action.BLUE }}
          onClick={() => onSelectedSka(keyActivity.id, keyActivity.teamId)}
        />
      </td>
    </tr>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_ACTIVITY_TABLE_ROW__MITEM = gql`
  fragment MilestoneActivityTableRow__Mitem on Mitem {
    id
    name
    deadline
    completedAt
    status
    teamId
    team2 {
      id
      name
    }
  }
`;
