import { Input, Form, Divider, FormInstance } from 'antd';
import {
  FlagOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { skaValidationRules } from '../../../../appPages/team/common/utils/useSkaValidationRules';
import { TagsSelector } from '../../../../appPages/team/sprint/planning/components/MitemForm/TagsItem';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';
import { Btn } from '../../../Button';
import { AlignmentIcon } from '../../../../icons/formIcons/AlignmentIcon';
import { EndingPointIcon } from '../../../../icons/formIcons/EndingPointIcon';
import { PersonSearchIcon } from '../../../../icons/formIcons/PersonSearchIcon';
import { TargetIcon } from '../../../../icons/formIcons/TargetIcon';
import { TeamTagIcon } from '../../../../icons/formIcons/TeamTagIcon';
import { TextAreaIconIcon } from '../../../../icons/formIcons/TextAreaIcon';
import { TitleIcon } from '../../../../icons/formIcons/TitleIcon';
import { DeadlinePickerFormItem } from './formItems/DeadlinePickerFormItem';
import { InitiativeSelectFormItem } from './formItems/InitiativeSelectFormItem';
import { MemberSelectFormItem } from './formItems/MemberSelectFormItem';
import { MigSelectFormItem } from './formItems/MigSelectFormItem';
import { MilestoneSelectFormItem } from './formItems/MilestoneSelectFormItem';
import { ActivityFullFormValues } from './CreateActivityFullVersion'; // Keep type export for now
import { gql } from '@apollo/client';
import {
  ActivityForm_InitiativeFragment,
  ActivityForm_MilestoneFragment,
  ActivityForm_User2Fragment,
} from '../../../../generated/graphql';

// Zod schema remains the same
const dayjsSchema = z.custom<Dayjs>(
  (data) => data && typeof data.isValid === 'function' && data.isValid(),
  'Invalid date'
);
const alignmentSchema = z.object({
  initiativeId: z.string().optional(),
  milestoneId: z.string().optional(),
});
export const activityFormSchema = z.object({
  name: z.string().min(1, 'Title is required'),
  alignments: z.array(alignmentSchema),
  deadline: dayjsSchema,
  ownerId: z.string().min(1, 'Owner is required'),
  definitionOfDone: z.string().max(1024).optional(),
  supportMigId: z.string().optional(),
  tags: z.array(z.string()).optional(),
});

// Type for validated form values
export type ValidatedActivityFormData = z.infer<typeof activityFormSchema>;

interface Props {
  form: FormInstance<any>; // Ant Design form instance
  teamId: string;
  onSubmit: (values: ValidatedActivityFormData) => void; // Callback with validated data
  initialValues?: ActivityFullFormValues;
  isCompleted?: boolean; // Specifically for disabling fields when editing a completed item
  submitPending?: boolean;
  submitButtonText: string;
  extraActions?: React.ReactNode; // For "Keep open" checkbox or other actions
  topAlert?: React.ReactNode; // For the "Completed" alert in edit mode
  // Props needed for Initiative/Milestone selects if they depend on fetched data in Edit mode
  alignedInitiatives?: ActivityForm_InitiativeFragment[]; // Use more specific type if available
  alignedMilestones?: ActivityForm_MilestoneFragment[]; // Use more specific type if available
  initialOwner?: ActivityForm_User2Fragment; // Use more specific type if available
}

export const ActivityForm = ({
  form,
  teamId,
  onSubmit,
  initialValues,
  isCompleted = false,
  submitPending = false,
  submitButtonText,
  extraActions,
  topAlert,
  alignedInitiatives,
  alignedMilestones,
  initialOwner,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();

  const handleFinish = (values: unknown) => {
    const result = activityFormSchema.safeParse(values);
    if (!result.success) {
      const formErrors = result.error.issues.map((issue) => ({
        name: issue.path,
        errors: [issue.message],
      }));
      form.setFields(formErrors);
      return;
    }
    onSubmit(result.data); // Pass validated data to the callback
  };

  const disableFields = isCompleted;

  return (
    <Form
      form={form}
      autoComplete="off"
      requiredMark={false}
      name="activity_form_base" // Unique name for the base form
      onFinish={handleFinish}
      colon={false}
      initialValues={initialValues}
    >
      {topAlert} {/* Render alert if provided (for Edit mode) */}
      <Form.Item
        name="name"
        label={<TitleIcon className="font-size--lg txt--secondary" />}
        required
        rules={skaValidationRules.name}
        data-intercom-target="Sprint Key Activity Form Name Input"
      >
        <Input
          autoComplete="off"
          autoFocus
          placeholder={t('common.title')}
          disabled={disableFields} // Disable based on prop
        />
      </Form.Item>
      {features.tenantInitiativesEnabled && (
        <Form.List name="alignments">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} className="flx">
                  <Form.Item
                    key={`${field.key}-initiative`}
                    label={
                      <AlignmentIcon className="font-size--lg txt--secondary" />
                    }
                    name={[field.name, 'initiativeId']}
                    className="mr--l flx--1"
                  >
                    <InitiativeSelectFormItem
                      teamId={teamId}
                      alignedInitiatives={alignedInitiatives}
                      onChange={() => {
                        const alignments = form.getFieldValue('alignments');
                        alignments[index].milestoneId = undefined;
                        form.setFieldsValue({ alignments });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    key={`${field.key}-milestone`}
                    label={
                      <FlagOutlined className="font-size--lg txt--secondary" />
                    }
                    className="flx--1 mr"
                    name={[field.name, 'milestoneId']}
                  >
                    <MilestoneSelectFormItem
                      teamId={teamId}
                      alignedMilestones={alignedMilestones}
                      selectedInitiativeId={form.getFieldValue([
                        'alignments',
                        index,
                        'initiativeId',
                      ])}
                    />
                  </Form.Item>
                  <Form.Item key={`${field.key}-remove`} label={null}>
                    {!disableFields &&
                      fields.length > 1 && ( // Only show remove if not disabled
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          className="txt--error"
                        />
                      )}
                  </Form.Item>
                </div>
              ))}

              {!disableFields && ( // Only show add button if not disabled
                <Form.Item>
                  <Btn
                    type="link"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    {t('EditActivity.addAnotherInitiativeAlignment')}{' '}
                    {/* Use consistent translation key */}
                  </Btn>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>
      )}
      <div className="flx flx--gap">
        <Form.Item
          name="deadline"
          label={<EndingPointIcon className="font-size--lg txt--secondary" />}
          required
          rules={[{ required: true }]}
        >
          <DeadlinePickerFormItem teamId={teamId} disabled={disableFields} />
        </Form.Item>

        <Form.Item
          name="ownerId"
          label={<PersonSearchIcon className="font-size--lg txt--secondary" />}
          className="flx--1"
          required
          rules={skaValidationRules.ownerId}
          data-intercom-target="Sprint Key Activity Form Owner Input"
        >
          {/* Pass initialOwner if in edit mode */}
          <MemberSelectFormItem
            teamId={teamId}
            initialOwner={initialOwner}
            disabled={disableFields}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="definitionOfDone"
        label={<TextAreaIconIcon className="font-size--lg txt--secondary" />}
        rules={skaValidationRules.definitionOfDone}
        data-intercom-target="Sprint Key Activity Form Definition of Done Input"
      >
        <Input.TextArea
          placeholder={t('common.definitionOfDone')}
          autoSize={{ minRows: 2, maxRows: 10 }}
          maxLength={1024}
          showCount
          disabled={disableFields}
        />
      </Form.Item>
      <Divider className="mb--l" />
      <div className="flx flx--gap">
        <Form.Item
          className="flx--1"
          label={<TargetIcon className="font-size--lg txt--secondary" />}
          name="supportMigId"
        >
          <MigSelectFormItem teamId={teamId} disabled={disableFields} />
        </Form.Item>
        <Form.Item
          name="tags"
          className="flx--1"
          label={<TeamTagIcon className="font-size--lg txt--secondary" />}
        >
          {/* TagsSelector might need initialTags prop if used in Edit mode */}
          <TagsSelector teamId={teamId} />
        </Form.Item>
      </div>
      <Form.Item label={null}>
        <div className="flx flx--jc-space-between borderTop pt--l flx--ai-center mt--xl">
          <div>{extraActions}</div>{' '}
          {/* Render extra actions like "Keep open" */}
          <div>
            <Btn type="primary" htmlType="submit" loading={submitPending}>
              {submitButtonText}
            </Btn>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_FRAGMENT = gql`
  fragment ActivityForm_initiative on InitiativeWithLinks {
    id
    ...InitiativeSelectFormItem_initiative
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MILESTONE_FRAGMENT = gql`
  fragment ActivityForm_milestone on MilestoneWithLinks {
    id
    ...MilestoneSelectFormItem_milestone
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_USER2_FRAGMENT = gql`
  fragment ActivityForm_user2 on User2 {
    id
    domainId {
      itemId
    }
    name
    email
    displayName
    initials
  }
`;
