import { gql, useSuspenseQuery } from '@apollo/client';
import { useTeamPermissions } from '../../usePermissions';
import {
  Action,
  MitemStatus,
  SprintKeyActivityDetails_MitemFragment,
  SprintKeyActivityDetails_QueryDocument,
} from '../../generated/graphql';
import { SkaDetailsContent } from '../../appPages/team/sprint/planning/components/mitemPlanningBoard/skaDetails/SkaDetailsContent';
import { Popconfirm, Spin, Tabs } from 'antd';
import { MitemHistory } from '../../appPages/team/sprint/common/components/MitemHistory';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Btn } from '../Button';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import './ActivityDetails.less';
import { ActivityDetailsSkeleton } from './ActivityDetails.skeleton';
import { useMe } from '../../hooks/useMe';
import { useEffect, useState } from 'react';
import { StatusTag } from '../StatusTag';
import { DeleteOutlineIcon } from '../../icons/initiativeIcons/DeleteOutlineIcon';
import { useMitemArchivation } from '../../appPages/team/sprint/common/services/useMitemArchivation';
import { showNotification } from '../../services/fetchNotificationProperties';
import { useModal } from '../../ModalProvider';
import { usePureKeyActivities } from '../../hooks/featureFlagHooks';
import { SprintKeyActivityReportForm } from './SprintKeyActivityReportForm';

interface Props {
  sprintKeyActivity: SprintKeyActivityDetails_MitemFragment;
  activeTab?: 'details' | 'history' | 'report';
  onClose: () => void;
}

export const ActivityDetails = ({
  sprintKeyActivity,
  activeTab,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [activeTabState, setActiveTab] = useState<
    'details' | 'history' | 'report'
  >(activeTab || 'details');
  const newActivityViewsEnabled = usePureKeyActivities();

  const me = useMe();
  const { isAllowed, loading } = useTeamPermissions({
    teamId: sprintKeyActivity.teamId,
    requestedAction: {
      action: Action.READ,
      resource: 'sprintKA',
    },
  });

  const { isAllowed: canEdit } = useTeamPermissions({
    teamId: sprintKeyActivity.teamId,
    requestedAction: {
      action: Action.UPDATE,
      resource: 'sprintKA',
    },
  });

  const { openModal } = useModal();

  const [archiveMitem, { loading: archivingLoading }] = useMitemArchivation({
    onCompleted() {
      onClose();
      showNotification('success', {
        message: t('ActivityDetails.deleteSuccess'),
      });
    },
    onError() {
      showNotification('error', {
        message: t('ActivityDetails.deleteError'),
      });
    },
  });

  const canMarkAsCompleted = canEdit && newActivityViewsEnabled;

  const userIsOwner =
    sprintKeyActivity.ownerId === me?.data?.currentUser.domainId.itemId;

  useEffect(() => {
    // Map the query param to tab keys
    if (activeTab === 'details') {
      setActiveTab('details');
    } else if (activeTab === 'history') {
      setActiveTab('history');
    } else if (activeTab === 'report' && (userIsOwner || canMarkAsCompleted)) {
      setActiveTab('report');
    } else {
      setActiveTab('details'); // Default tab key
    }
  }, [userIsOwner, canMarkAsCompleted, activeTab]);

  return (
    <div
      className={cx(
        'ActivityDetails',
        `ActivityDetails--${sprintKeyActivity.status}`
      )}
    >
      <Spin spinning={loading}>
        <div className="ActivityDetails__header">
          <div className="ActivityDetails__statusTagWrapper">
            <StatusTag status={sprintKeyActivity.status} />
          </div>
          <Btn
            shape="circle"
            type="text"
            onClick={onClose}
            className="ActivityDetails__closeButton"
            icon={<CloseOutlined />}
          />
        </div>

        <Tabs
          className="ml--xl mb--xl"
          size="small"
          tabBarStyle={{ fontSize: 12 }}
          activeKey={activeTabState}
          onChange={(key) =>
            setActiveTab(key as 'details' | 'history' | 'report')
          }
          items={[
            {
              key: 'details',
              label: t('common.details'),
              children: <SkaDetailsContent fullSKa={sprintKeyActivity} />,
            },
            {
              key: 'history',
              label: t('common.history'),
              disabled: loading || !isAllowed,
              children: (
                <div className="pl--xs">
                  <MitemHistory
                    teamId={sprintKeyActivity.teamId}
                    mitemId={sprintKeyActivity.id}
                  />
                </div>
              ),
            },
            // Conditionally include the "report" tab
            ...(userIsOwner || canMarkAsCompleted
              ? [
                  {
                    key: 'report',
                    label: t('ActivityDetails.report'),
                    children: (
                      <div className="pl--xs">
                        <SprintKeyActivityReportForm
                          keyActivity={sprintKeyActivity}
                          onCompleted={() => setActiveTab('history')}
                        />
                      </div>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Spin>
      {canEdit && (
        <div className="ActivityDetails__footer">
          {sprintKeyActivity.status !== MitemStatus.COMPLETED && (
            <Popconfirm
              title={t('ActivityDetails.confirmDelete')}
              onConfirm={() => {
                archiveMitem(sprintKeyActivity.teamId, sprintKeyActivity.id);
              }}
              onCancel={(e) => e?.stopPropagation()}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              disabled={archivingLoading}
            >
              <Btn type="link" icon={<DeleteOutlineIcon />}>
                {t('common.delete')}
              </Btn>
            </Popconfirm>
          )}
          <Btn
            type="link"
            icon={<EditOutlined />}
            onClick={() => {
              openModal({
                type: 'edit',
                activity: sprintKeyActivity,
              });
            }}
          >
            {t('common.edit')}
          </Btn>
        </div>
      )}
    </div>
  );
};

ActivityDetails.skeleton = ActivityDetailsSkeleton;

interface WrapperProps {
  skaId: string;
  teamId: string;
  activeTab?: 'details' | 'history' | 'report';
  onClose: () => void;
}

export const SprintKeyActivityDetailsWrapper = ({
  skaId,
  teamId,
  activeTab,
  onClose,
}: WrapperProps) => {
  const { data } = useSuspenseQuery(SprintKeyActivityDetails_QueryDocument, {
    variables: { skaId, teamId },
  });

  return (
    <ActivityDetails
      sprintKeyActivity={data.mitem}
      activeTab={activeTab}
      onClose={onClose}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITY = gql`
  query SprintKeyActivityDetails_Query($teamId: ID!, $skaId: ID!) {
    mitem(teamId: $teamId, mitemId: $skaId) {
      ...SprintKeyActivityDetails_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment SprintKeyActivityDetails_Mitem on Mitem {
    id
    name
    teamId
    status
    ownerId
    ...SkaDetailsContent_Mitem
    ...SprintKeyActivityReportForm__Mitem
  }
`;
