import { BulbOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { envConf } from '../../envConf';
import { useMyTeams } from '../../hooks/useMyTeams';
import { useTenantOverride } from '../../TenantOverrideProvider';
import { ExternalLinkIcon } from '../../icons/ExternalLinkIcon';
import { MigIcon } from '../../icons/MigIcon';
import { TasksIcon } from '../../icons/TasksIcon';
import { UserMenu } from './Navbar/components/UserMenu';
import { ReportDrawerButton } from './ReportDrawerButton';
import { TabDropdown, TabLink } from './TabLink';
import './GlobalNav.less';
import { useCurrentLocale } from '../../hooks/useCurrentLocale';
import { AppLogo } from './AppLogo';

export const GlobalNav = ({ hasWhy }: { hasWhy?: boolean }) => {
  const { t } = useTranslation();
  const tenantOverride = useTenantOverride();

  const teamRouteMatch = useRouteMatch('/team');
  const { teams, loading } = useMyTeams();

  const teamMenuItems: MenuProps['items'] = teams.map((t, index) => {
    const url = `/team/${t.domainId.itemId}`;
    return {
      label: (
        <NavDropdownItem
          title={t.name}
          key={t.id}
          url={url}
          intercom={`Team Navigation ${index + 1}`}
        />
      ),
      key: t.id,
    };
  });

  const singleTeamId = teams.length === 1 ? teams[0].domainId.itemId : null;

  const academyItems = useAcademyMenuItems();

  return (
    <div className="GlobalNav">
      <Link
        className="GlobalNav__howwe"
        to="/"
        data-intercom-target="Main Navigation"
      >
        <AppLogo />
      </Link>

      <div className="GlobalNav__mainNav">
        <TabLink
          className="GlobalNav__link"
          activeClassName="GlobalNav__link--active"
          to="/company/acceleration-board"
          isActive={(match, location) =>
            location.pathname.startsWith('/company/')
          }
          data-intercom-target="Company navigation"
        >
          {t('GlobalNav.company')}
        </TabLink>
        {!tenantOverride.isOverridden && (
          <>
            {singleTeamId || loading ? (
              <TabLink
                className="GlobalNav__link"
                activeClassName="GlobalNav__link--active"
                to={`/team/${singleTeamId}`}
                loading={loading}
                data-intercom-target="My Teams"
                data-testid="my-team-link"
              >
                {t('GlobalNav.myTeam')}
              </TabLink>
            ) : (
              <TabDropdown
                className="GlobalNav__link"
                activeClassName="GlobalNav__link--active"
                menu={{ items: teamMenuItems }}
                isActive={teamRouteMatch != null}
                dataTestId="my-teams-dropdown"
              >
                {t('GlobalNav.myTeams')}
              </TabDropdown>
            )}
            <TabLink
              className="GlobalNav__link"
              activeClassName="GlobalNav__link--active"
              to="/my-page/achievements"
              data-intercom-target="My Page"
              data-testid="my-page"
            >
              {t('GlobalNav.myPage')}
            </TabLink>
          </>
        )}
        <TabDropdown menu={{ items: academyItems }} className="GlobalNav__link">
          {t('GlobalNav.knowledgeCenter')}
        </TabDropdown>
        {hasWhy && (
          <TabLink
            className="GlobalNav__link"
            activeClassName="GlobalNav__link--active"
            data-intercom-target="Company Why navigation"
            to="/why"
            data-testid="why-page"
          >
            {t('GlobalNav.companyWhy')}
          </TabLink>
        )}
      </div>
      <div className="GlobalNav__userActions">
        <div className="pr--l mr--l borderRight">
          <UserMenu />
        </div>
        <ReportDrawerButton />
      </div>
    </div>
  );
};

const useAcademyMenuItems = () => {
  const { t } = useTranslation();
  const { locale } = useCurrentLocale();

  const academyTrackUrl =
    '?utm_source=app.howwe.io&utm_medium=web&utm_campaign=side_nav';

  const academyItems: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <NavDropdownItem
          icon={<TasksIcon />}
          url={envConf.ACADEMY_BASE_URL + `/${locale}/` + academyTrackUrl}
          title={t('Sidebar.academy')}
          externalLink
          intercom="Academy"
        />
      ),
    },
    {
      key: 2,
      label: (
        <NavDropdownItem
          icon={<DeploymentUnitOutlined style={{ fontSize: 20, padding: 5 }} />}
          url={
            envConf.ACADEMY_BASE_URL +
            `/${locale}/community-${locale}` +
            academyTrackUrl
          }
          title={t('Sidebar.community')}
          externalLink
          intercom="Community"
        />
      ),
    },
    {
      key: 3,
      label: (
        <NavDropdownItem
          icon={<MigIcon className="menuIcon" />}
          url={
            envConf.ACADEMY_BASE_URL +
            `/${locale}/library-${locale}` +
            academyTrackUrl
          }
          title={t('Sidebar.library')}
          externalLink
          intercom="Library"
        />
      ),
    },
    {
      key: 4,
      label: (
        <NavDropdownItem
          icon={<BulbOutlined style={{ fontSize: 20, padding: 5 }} />}
          url={envConf.HELPCENTER_BASE_URL}
          title={t('Sidebar.helpCenter')}
          externalLink
          intercom="HelpCenter"
        />
      ),
    },
  ];

  return academyItems;
};

type MenuItemProps =
  | {
      title: string;
      url: string;
      icon?: React.ReactNode;
      externalLink: true;
      intercom?: string;
    }
  | {
      title: string;
      url: string;
      isActive?: boolean;
      icon?: React.ReactNode;
      externalLink?: false;
      intercom?: string;
    };

const NavDropdownItem = (props: MenuItemProps) => {
  return props.externalLink ? (
    <a
      className="flx flx--jc-space-between"
      href={props.url}
      target="_blank"
      rel="noreferrer"
      data-intercom-target={props.intercom}
    >
      <div className="flx flx--ai-center">
        {props.icon && <div className="mr">{props.icon}</div>}
        {props.title}
      </div>
      <div className="ml--l">
        <ExternalLinkIcon style={{ fontSize: 8 }} />
      </div>
    </a>
  ) : (
    <Link to={props.url} data-intercom-target={props.intercom}>
      {props.icon && <div className="mr">{props.icon}</div>}
      <span className="ellipsis" title={props.title}>
        {props.title}
      </span>
    </Link>
  );
};
