import { FlagOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ActivityStatus } from '../../../../../components/ActivityStatus';
import {
  groupInitiativesAndMilestones,
  TableMitemDetails,
} from '../../../../../components/akpi/TableMitemDetails';
import { ExpandArrow } from '../../../../../components/ExpandArrow';
import { InitiativeIcon } from '../../../../../components/initiative/InitiativeIcon';
import { UserAvatar } from '../../../../../components/UserAvatar';
import {
  Action,
  ActivityTable_MitemFragment,
  EditActivityDeadlineDocument,
} from '../../../../../generated/graphql';
import {
  API_DATE_FORMAT,
  friendlyDate,
} from '../../../../../services/dateFormats';
import { stringSort } from '../../../../../services/stringSort';
import { TagImage } from '../../../setup/overviewPage/labels/TagImage';
import { ActivityTableSkeleton } from './ActivityTable.Skeleton';
import { ActivityActions } from './ActivityActions';
import { useTeamPermissions } from '../../../../../usePermissions';
import { ActivityCommitmentSwitch } from '../../../overview/components/accelerationMeetingModal/accelerationMeetingContainer/ActivityCommitmentSwitch';
import './ActivityTable.less';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { DeadlineDeluxe } from '../../../../../components/DeadlineDeluxe';
import { isEndOfPeriod } from '../../../../../services/accelerationDayHelpers';
import { useAccelerationDay } from '../../../../../hooks/useAccelerationDay';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { DisplayName } from '../../../../../components/DisplayName';
import { useState } from 'react';

interface Props {
  teamId: string;
  activities: ActivityTable_MitemFragment[];
  header: React.ReactNode;
  className?: string;
  committable?: boolean;
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const ActivityTable = ({
  teamId,
  activities,
  header,
  committable = false,
  className,
}: Props) => {
  const { t } = useTranslation();
  const { data: accelerationDay } = useAccelerationDay(teamId);
  const { isAllowed: isAllowedToEditActivity } = useTeamPermissions({
    teamId: teamId,
    requestedAction: {
      action: Action.UPDATE,
      resource: 'sprintKA',
    },
  });

  const [editActivityDeadline, { loading }] = useMutation(
    EditActivityDeadlineDocument
  );

  const handleChange = (
    newDeadline: Dayjs,
    teamId: string,
    activityId: string
  ) => {
    editActivityDeadline({
      variables: {
        teamId: teamId,
        sprintKaId: activityId,
        sprintKaData: {
          deadline: newDeadline.format(API_DATE_FORMAT),
        },
      },
      onCompleted: (data) => {
        showNotification('success', {
          message: (
            <strong>
              {t('ActivityTable.successMessage', {
                name: data.editSprintKa.name,
                deadline: friendlyDate(data.editSprintKa.deadline),
              })}
            </strong>
          ),
        });
      },
      onError: () => {
        showNotification('error', {
          message: <strong>{t('ActivityTable.errorMessage')}</strong>,
        });
      },
    });
  };

  // Add state for expanded row
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);

  const canCommit = committable && isAllowedToEditActivity;
  const activityTableColumns: ColumnPropsWithExclude<ActivityTable_MitemFragment>[] =
    [
      {
        title: t('common.sprintKeyActivity.deadline'),
        sorter: (a, b) => stringSort(a.deadline, b.deadline),
        defaultSortOrder: 'ascend',
        width: 120,
        render: (_, activity) => {
          if (!isAllowedToEditActivity) {
            return <span>{friendlyDate(activity.deadline)}</span>;
          }

          return (
            <Spin spinning={loading} size="small">
              <DeadlineDeluxe
                deadline={activity.deadline}
                onChange={(date) => handleChange(date, teamId, activity.id)}
                disabledDate={(date) =>
                  (!!accelerationDay &&
                    !isEndOfPeriod(date, accelerationDay)) ||
                  date.isBefore(dayjs())
                }
              />
            </Spin>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.status'),
        width: 110,
        sorter: (a, b) => stringSort(a.status, b.status),
        render: (_, activity) => {
          return <ActivityStatus status={activity.status} />;
        },
      },
      {
        title: t('common.title'),
        sorter: (a, b) => stringSort(a.name, b.name),
        render: (_, activity) => {
          return (
            <div>
              <h4>{activity.name}</h4>
              <div className="txt--secondary font-size--sm">
                {groupInitiativesAndMilestones(activity).map(
                  (
                    { initiative, milestones },
                    initiativeIndex,
                    initiativesArray
                  ) => (
                    <div
                      key={initiative.domainId.itemId}
                      className={`flx ${initiativeIndex < initiativesArray.length - 1 ? 'mb--xxs' : ''}`}
                    >
                      <div className="ActivityTable__alignment">
                        <div className="flx flx--ai-center">
                          <InitiativeIcon iconId={initiative.tag.iconId} />
                          <div className="ml--s">{initiative.tag.title}</div>
                        </div>
                      </div>
                      {milestones.length > 0 && (
                        <div className="ActivityTable__milestones">
                          {milestones.map((ms, index) => (
                            <div
                              key={ms.domainId.itemId}
                              className={
                                index < milestones.length - 1 ? 'mb--xs' : ''
                              }
                            >
                              <div className="flx mb--xs flx--ai-center">
                                <FlagOutlined className="mr--s" />
                                <div className="ellipsis"> {ms.name} </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Owner',
        width: 160,
        ellipsis: true,
        sorter: (a, b) =>
          stringSort(friendlyUsername(a.owner2), friendlyUsername(b.owner2)),
        render: (_, activity) => {
          return (
            <span className="no-wrap">
              <span className="mr--s">
                <UserAvatar user={activity.owner2} size="small" />
              </span>
              <span>
                <DisplayName user={activity.owner2} />
              </span>
            </span>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.tags'),
        width: 160,
        render: (_, activity) => {
          const tags = activity.tags.map((tag) => (
            <TagImage key={tag.id} tag={tag} />
          ));

          return (
            <div className="flx flx--gap--xs flx--wrap">
              {tags.length > 0 ? tags : ''}
            </div>
          );
        },
      },
      {
        width: canCommit ? 120 : 90,
        fixed: 'right',
        render: (_, activity) => (
          <div className="flx flx--ai-center flx--jc-end">
            {canCommit && (
              <ActivityCommitmentSwitch
                activityId={activity.id}
                userId={activity.owner2.domainId.itemId}
              />
            )}
            <ActivityActions teamId={activity.teamId} activity={activity} />
            <ExpandArrow
              expanded={expandedRowId === activity.id}
              onClick={(e) => {
                e.stopPropagation();
                setExpandedRowId(
                  expandedRowId === activity.id ? null : activity.id
                );
              }}
            />
          </div>
        ),
      },
    ];

  return (
    <div className={className}>
      {header}

      <Table
        columns={activityTableColumns.filter((col) => !col.excluded)}
        rowKey={(activity) => activity.id}
        dataSource={activities}
        pagination={false}
        expandable={{
          expandRowByClick: true,
          expandedRowKeys: expandedRowId ? [expandedRowId] : [],
          expandedRowRender: (record) => {
            return (
              <div className="ml--xxl mr--xxl">
                <TableMitemDetails mitem={record} committable={canCommit} />
              </div>
            );
          },
          expandIcon: () => null, // Hide default expand icon since we're using our own
          onExpand: (expanded, record) => {
            setExpandedRowId(expanded ? record.id : null);
          },
        }}
      />
    </div>
  );
};

const Header = ({
  startDate,
  actions,
}: {
  startDate: Dayjs;
  actions?: React.ReactNode;
}) => {
  const isCurrentYear = startDate.isSame(dayjs(), 'year');
  const format = isCurrentYear ? 'MMMM' : 'MMMM YYYY';
  return (
    <div className="flx mb--s">
      <h4>{startDate.format(format)}</h4>
      <div className="flx--1">{actions}</div>
    </div>
  );
};

ActivityTable.Header = Header;
ActivityTable.Skeleton = ActivityTableSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_ACTIVITY_DEADLINE = gql`
  mutation editActivityDeadline(
    $teamId: ID!
    $sprintKaId: ID!
    $sprintKaData: SprintKaInput!
  ) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $sprintKaId
      sprintKaData: $sprintKaData
    ) {
      id
      name
      deadline
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_TABLE__KEY_ACTIVITIES = gql`
  fragment ActivityTable_Mitem on Mitem {
    id
    name
    deadline
    status
    definitionOfDone
    tags {
      id
      name
      backgroundColor
    }
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        description
        domainId {
          itemId
        }
        metadata {
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        description
        domainId {
          itemId
        }
        tag {
          title
          iconId
          colorCode
        }
      }
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      name
      initials
      displayName
    }
    ...TableMitemDetails_Mitem
    ...ActivityActions_Mitem
  }
`;
