import { gql } from '@apollo/client';
import { KeyActivityStatusIcon } from '../../../../../components/KeyActivityStatusIcon';
import { getSimpleStatus } from '../../../../../services/mitemUtils';
import { ActivityCard_MitemFragment } from '../../../../../generated/graphql';
import { BaseCard } from './BaseCard';
// Remove old hook import
import { useModal } from '../../../../../ModalProvider'; // Import useModal

interface Props {
  activity: ActivityCard_MitemFragment;
}

export const ActivityCard = ({ activity }: Props) => {
  const { openModal } = useModal(); // Use modal context hook

  return (
    <BaseCard
      className="mb--s clickable"
      onClick={() =>
        openModal({
          type: 'activityDetails',
          urlBased: true,
          params: {
            activityId: activity.id,
            teamId: activity.teamId,
          },
        })
      }
    >
      <KeyActivityStatusIcon status={getSimpleStatus(activity.status)} />
      {activity.name}
    </BaseCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_CARDS__MITEM = gql`
  fragment ActivityCard_Mitem on Mitem {
    id
    name
    status
    teamId
  }
`;
