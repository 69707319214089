import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ArrowUpOutlined, CalendarOutlined } from '@ant-design/icons';
import { Btn } from '../../../../../components/Button';

export const ActivityTableSkeleton = ({
  showActions,
}: {
  showActions?: boolean;
}) => {
  const { t } = useTranslation();

  const activityTableColumns: ColumnProps<any>[] = [
    {
      title: t('common.sprintKeyActivity.status'),
      render: () => {
        return <Skeleton />;
      },
    },
    {
      title: t('common.title'),
      render: () => {
        return <Skeleton />;
      },
    },
    {
      title: t('common.sprintKeyActivity.deadline'),
      render: () => {
        return <Skeleton />;
      },
    },
    {
      title: t('common.sprintKeyActivity.tags'),
      render: () => {
        return <Skeleton />;
      },
    },
    {
      title: 'Owner',
      render: () => {
        return <Skeleton />;
      },
    },
  ];

  return (
    <div className="mb--xl mt--xl">
      <div className="flx flx--ai-center">
        <h2>
          <Skeleton width={100} />
        </h2>
        {showActions && (
          <>
            <Btn
              disabled
              size="small"
              type="link"
              icon={<CalendarOutlined />}
            ></Btn>
            <Btn
              disabled={true}
              icon={<ArrowUpOutlined />}
              size="small"
              type="link"
            ></Btn>
          </>
        )}
      </div>
      <Table
        columns={activityTableColumns}
        rowKey={(activity) => activity.id}
        dataSource={[{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]}
        pagination={false}
      />
    </div>
  );
};
