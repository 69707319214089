import { Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Btn } from '../../../../../../../../../components/Button';
import { MarkAsArchivedIcon } from '../../../../../../../../../icons/MarkAsArchivedIcon';

export const ArchiveMilestoneModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('ArchiveMilestoneModalContent.title')}</h3>
      <Skeleton width={300} />
      <div className="mt">
        <MarkAsArchivedIcon className="txt--warning" style={{ fontSize: 70 }} />
      </div>
      <Divider />
      <div>
        <Btn disabled type="success">
          {t('ArchiveMilestoneModalContent.markAsArchived')}
        </Btn>
      </div>
    </div>
  );
};
