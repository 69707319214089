import dayjs from 'dayjs';
import { useModal } from '../../../../../../../../../ModalProvider';
import { useMemo, useState } from 'react';
import {
  getMonthsBetween,
  groupDeadlinesByMonth,
} from '../../../../../../../activities/TeamActivitiesRoutes';
import { gql, useQuery, useSuspenseQuery } from '@apollo/client';
import { ActivityTable } from '../../../../../../../activities/listPage/ActivityTable/ActivityTable';
import { Btn } from '../../../../../../../../../components/Button';
import { ArrowDownOutlined } from '@ant-design/icons';
import {
  GetActivitiesForMonthBasedCommitmentStepDocument,
  GetOverdueActivitiesForMonthBasedCommitmentStepDocument,
} from '../../../../../../../../../generated/graphql';

interface Props {
  teamId: string;
}

export const MonthBasedCommitments = ({ teamId }: Props) => {
  const { openModal } = useModal();
  const [dateRange, setDateRange] = useState(() => ({
    startDate: dayjs().startOf('month'),
    endDate: dayjs().add(4, 'month').endOf('month'),
  }));
  const monthsToDisplay = getMonthsBetween(
    dateRange.startDate,
    dateRange.endDate
  );
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const { data, fetchMore } = useQuery(
    GetActivitiesForMonthBasedCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().add(4, 'month').endOf('month').format('YYYY-MM-DD'),
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    }
  );

  const overdueActivitiesData = useSuspenseQuery(
    GetOverdueActivitiesForMonthBasedCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
      },
    }
  );

  const overdueActivities =
    overdueActivitiesData.data.sprintKeyActivities.sprintKeyActivities ?? [];

  const groupedActivities = useMemo(() => {
    return groupDeadlinesByMonth(
      data?.sprintKeyActivities.sprintKeyActivities?.filter(
        (ska) => ska.completed === false
      ) ?? []
    );
  }, [data]);

  return (
    <div className="mt--xl flx">
      <div className="flx--1">
        <h3>Activities</h3>

        {overdueActivities.length !== 0 && (
          <ActivityTable
            teamId={teamId}
            committable={true}
            className="mt--xl"
            activities={overdueActivities}
            header={
              <h4 className="mb--s">Overdue activities from previous months</h4>
            }
          />
        )}
        {monthsToDisplay.map((monthKey) => (
          <ActivityTable
            teamId={teamId}
            committable={true}
            key={monthKey}
            className="mt--xl"
            activities={groupedActivities.get(monthKey) ?? []}
            header={
              <ActivityTable.Header
                startDate={dayjs(monthKey)}
                actions={
                  <div className="flx">
                    <Btn
                      size="small"
                      type="link"
                      className="ml--auto"
                      onClick={() =>
                        openModal({ type: 'createActivity', teamId })
                      }
                    >
                      + Add Activity
                    </Btn>
                  </div>
                }
              />
            }
          />
        ))}
        <Btn
          loading={fetchMoreLoading}
          icon={<ArrowDownOutlined />}
          size="small"
          className="mt"
          onClick={() => {
            const nextMonth = dateRange.endDate.add(1, 'months');
            fetchMore({
              variables: {
                teamId: teamId,
                startDate: nextMonth.startOf('month').format('YYYY-MM-DD'),
                endDate: nextMonth.endOf('month').format('YYYY-MM-DD'),
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                setFetchMoreLoading(false);
                if (!fetchMoreResult) return prev;

                const existingActivities =
                  prev.sprintKeyActivities.sprintKeyActivities ?? [];
                const newActivities =
                  fetchMoreResult.sprintKeyActivities.sprintKeyActivities ?? [];

                const existingIds = new Set(
                  existingActivities.map((a) => a.id)
                );
                const uniqueNewActivities = newActivities.filter(
                  (a) => !existingIds.has(a.id)
                );
                return {
                  __typename: prev.__typename,
                  sprintKeyActivities: {
                    __typename: prev.sprintKeyActivities.__typename,
                    sprintKeyActivities: [
                      ...existingActivities,
                      ...uniqueNewActivities,
                    ],
                  },
                };
              },
            }).then(() => {
              setDateRange({
                ...dateRange,
                endDate: nextMonth.endOf('month'),
              });
            });
          }}
        >
          Load More
        </Btn>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_LIST__MITEM = gql`
  fragment MonthBasedCommitments_Mitem on Mitem {
    id
    name
    deadline
    completed
    owner2 {
      id
      name
      email
      initials
      displayName
      domainId {
        itemId
      }
    }
    ...ActivityTable_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS = gql`
  query GetActivitiesForMonthBasedCommitmentStep(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        id
        ...MonthBasedCommitments_Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_OVERDUE_MITEMS = gql`
  query GetOverdueActivitiesForMonthBasedCommitmentStep(
    $teamId: ID!
    $startDate: String
    $endDate: String
  ) {
    sprintKeyActivities(
      teamId: $teamId
      overdue: true
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        id
        ...MonthBasedCommitments_Mitem
      }
    }
  }
`;
