import { CheckIcon } from '../../../../../icons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../icons/UpcomingEventIcon';
import { WarningIcon } from '../../../../../icons/WarningIcon';
import { Colors } from '../../../../../appPages/componentLibrary/Colors';
import './MilestoneStats.less';
import { MilestoneStatsSkeleton } from './MilestoneStats.skeleton';
import { InitiativeDetailsTreeNode } from '../../../../../appPages/company/initiatives/initiativeDetails/InitiativeDetails';

interface Props {
  initiativeNode: InitiativeDetailsTreeNode;
  isExpanded?: boolean;
}

export const MilestoneStats = ({ initiativeNode, isExpanded }: Props) => {
  const stats = !isExpanded
    ? initiativeNode.data.accumulatedMilestones.stats
    : initiativeNode.data.stats.milestones;
  return (
    <div className="MilestoneStats">
      <div className="MilestoneStats__stat">{stats.completed}</div>
      <div className="MilestoneStats__stat">{stats.overdue}</div>
      <div className="MilestoneStats__stat">{stats.upcoming}</div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="MilestoneStats">
      <div className="MilestoneStats__stat">
        <CheckIcon className="txt--success" style={{ fontSize: 15 }} />
      </div>
      <div className="MilestoneStats__stat">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      </div>
      <div className="MilestoneStats__stat">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      </div>
    </div>
  );
};

MilestoneStats.Header = Header;
MilestoneStats.Skeleton = MilestoneStatsSkeleton;
