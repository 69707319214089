import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import {
  InitiativeSelectFormItem_InitiativeFragment,
  GetInitsForInitiativeSelectFormItemDocument,
} from '../../../../../generated/graphql';
import { InitiativeIcon } from '../../../../initiative/InitiativeIcon';

interface Props {
  teamId: string;
  value?: string;
  onChange?: (value: string) => void;
  alignedInitiatives?: InitiativeSelectFormItem_InitiativeFragment[];
}

export const InitiativeSelectFormItem = ({
  teamId,
  value,
  onChange,

  alignedInitiatives,
}: Props) => {
  const { data: initiativeData, loading } = useQuery(
    GetInitsForInitiativeSelectFormItemDocument,
    {
      variables: { teamId },
    }
  );

  const activeInitiatives =
    initiativeData?.initiativesLinkedDirectlyOrIndirectlyToTeam.initiatives.filter(
      (initiative) =>
        initiative.metadata.completedAt == null && !initiative.metadata.archived
    ) ?? [];

  // Find disconnected initiatives
  const disconnectedInitiatives =
    alignedInitiatives?.filter(
      (alignedInit) =>
        !activeInitiatives.some((init) => init.id === alignedInit.id)
    ) ?? [];

  // Create options array with both active and disconnected initiatives
  const options = [
    ...activeInitiatives.map((i) => ({
      label: (
        <div className="font-size--sm">
          <InitiativeIcon iconId={i.tag.iconId} />
          <span className="space--l">{i.tag.title}</span>
        </div>
      ),
      value: i.id,
    })),
    ...(disconnectedInitiatives.length > 0
      ? [
          {
            label: 'No longer tracked',
            options: disconnectedInitiatives.map((i) => ({
              label: (
                <div className="font-size--sm txt--secondary">
                  <InitiativeIcon iconId={i.tag.iconId} />
                  <span className="space--l">{i.tag.title}</span>
                </div>
              ),
              value: i.id,
            })),
          },
        ]
      : []),
  ];

  return (
    <>
      <Select
        placeholder="Select initiative"
        value={value}
        onChange={onChange}
        loading={loading}
        allowClear
        options={options}
        optionLabelProp="label"
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_INITIATIVES_FOR_TEAM = gql`
  query GetInitsForInitiativeSelectFormItem($tenantId: ID, $teamId: ID!) {
    initiativesLinkedDirectlyOrIndirectlyToTeam(
      tenantId: $tenantId
      teamId: $teamId
    ) {
      id
      initiatives {
        ...InitiativeSelectFormItem_initiative
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FRAGMENT = gql`
  fragment InitiativeSelectFormItem_initiative on InitiativeWithLinks {
    id
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      status
      archived
    }
  }
`;
