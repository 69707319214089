import { Colors } from '../../appPages/componentLibrary/Colors';
import { InitiativeIcon } from './InitiativeIcon';
import './InitiativeTag.less';
import { CheckIcon } from '../../icons/CheckIcon';
import { match } from 'ts-pattern';
import { ArchivedIcon } from '../../icons/ArchivedIcon';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { getInitiativeBorderColor } from '../../appPages/company/reportGenerator/initiativeBorderColor';

interface PropsAlt1 {
  title: string;
  borderColor: string;
  icon: React.ReactNode;
  hideTitle?: boolean;
  completed?: boolean;
  archived?: boolean;
  className?: string;
  height?: number;
  useBgColor?: boolean;
}

interface PropsAlt2 {
  tag: { iconId: string; title: string; colorCode: string };
  hideTitle?: boolean;
  completed?: boolean;
  archived?: boolean;
  className?: string;
  height?: number;
  useBgColor?: boolean;
}

type Props = PropsAlt1 | PropsAlt2;

export const InitiativeTag = (props: Props) => {
  const { t } = useTranslation();
  let icon, title, borderColor, bgColor;

  if ('tag' in props) {
    icon = <InitiativeIcon iconId={props.tag.iconId} />;
    title = props.tag.title;
    borderColor = props.tag.colorCode;
  } else {
    icon = props.icon;
    title = props.title;
    borderColor = props.borderColor ?? Colors.Grays.WHITE;
  }

  if (props.useBgColor) {
    bgColor = borderColor;
    borderColor = getInitiativeBorderColor(bgColor);
  }

  const infoIcon = match(props)
    .with({ archived: true }, () => (
      <Tooltip placement="topRight" title={t('InitiativeTag.archived')}>
        <div className="InitiativeTag__icon InitiativeTag__icon--archived">
          <ArchivedIcon />
        </div>
      </Tooltip>
    ))
    .with({ completed: true }, () => (
      <Tooltip placement="topRight" title={t('InitiativeTag.completed')}>
        <div className="InitiativeTag__icon InitiativeTag__icon--completed">
          <CheckIcon className="txt--success" />
        </div>
      </Tooltip>
    ))
    .otherwise(() => null);

  return (
    <div
      className={cx('InitiativeTag', props.className)}
      style={{
        borderColor: borderColor,
        height: props.height,
        backgroundColor: bgColor,
      }}
    >
      {infoIcon}
      <div className="flx InitiativeTag__content">
        <div
          className={!props.hideTitle ? 'mr--s' : ''}
          style={{ fontSize: 12 }}
        >
          {icon}
        </div>
        {!props.hideTitle && title}
      </div>
    </div>
  );
};
