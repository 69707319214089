import { Divider } from 'antd';
import { Btn } from '../../../../Button';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { MarkAsArchivedIcon } from '../../../../../icons/MarkAsArchivedIcon';
import { ArchiveIcon } from '../../../../../icons/initiativeIcons/ArchiveIcon';

export const MarkInitiativeAsArchivedModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('MarkInitiativeAsArchivedModalContent.title')}</h3>
      <Skeleton />
      <div className="mt">
        <MarkAsArchivedIcon className="txt--warning" style={{ fontSize: 70 }} />
      </div>
      <div className="mt">
        {t('MarkInitiativeAsArchivedModalContent.infoText')}
      </div>
      <Divider />
      <div>
        <Btn icon={<ArchiveIcon />} type="primary" disabled>
          {t('MarkInitiativeAsArchivedModalContent.markAsArchived')}
        </Btn>
      </div>
    </div>
  );
};
