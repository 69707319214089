import { gql, useSuspenseQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { ActivityTable } from '../../../../../../../../../appPages/team/activities/listPage/ActivityTable/ActivityTable';
import { Btn } from '../../../../../../../../../components/Button';
import { useModal } from '../../../../../../../../../ModalProvider';
import {
  GetActivitiesForInitiativeBasedCommitmentStepDocument,
  InitiativeBasedCommitments_MitemFragment,
  InitiativeCommitmentDataFragment,
  MilestoneCommitmentDataFragment,
} from '../../../../../../../../../generated/graphql';
import { getUnique } from '../../../../../../../../../services/getUnique';
import { InitiativeIcon } from '../../../../../../../../../components/initiative/InitiativeIcon';
import { MilestoneStatusIcon } from '../../../../../../../../../components/MilestoneStatusIcon';
import { DisplayDate } from '../../../../../../../../../components/DisplayDate';
import { friendlyUsername } from '../../../../../../../../../services/friendlyUsername';
import { TextAreaIconIcon } from '../../../../../../../../../icons/formIcons/TextAreaIcon';
import { EndingPointIcon } from '../../../../../../../../../icons/formIcons/EndingPointIcon';
import { PersonShieldIcon } from '../../../../../../../../../icons/PersonShield';

interface Props {
  teamId: string;
}

export const InitiativeBasedCommitments = ({ teamId }: Props) => {
  const { openModal } = useModal();

  const { data } = useSuspenseQuery(
    GetActivitiesForInitiativeBasedCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        archived: false, // Fetch only non-archived activities
      },
    }
  );

  // Process and sort activities using helper functions
  const { groupedByInitiative, activitiesWithoutConnection } = useMemo(() => {
    const allActivities = data?.sprintKeyActivities.sprintKeyActivities ?? [];
    // Filter out completed activities before grouping
    const activities = allActivities.filter(
      (activity) => !activity.completed && !activity.archived
    );

    // Step 1: Group activities
    const { initiativeMap, unconnected } =
      groupActivitiesByInitiative(activities);

    // Step 2: Sort the grouped and unconnected activities
    return sortGroupedAndUnconnectedActivities(initiativeMap, unconnected);
  }, [data]);

  return (
    <div className="mt--xl flx">
      <div className="flx--1">
        {groupedByInitiative.map((group) => (
          <div key={group.initiative.id} className="borderBottom pb mb--xxl">
            {/* Initiative Header */}
            <h3 className="mb--s">
              <span className="mr--s">
                <InitiativeIcon iconId={group.initiative.tag.iconId} />
              </span>
              {group.initiative.name}
            </h3>

            {/* Milestones within Initiative */}
            {Array.from(group.milestones.values()).map(
              ({ milestone, activities }) => (
                <ActivityTable
                  key={milestone.id}
                  teamId={teamId}
                  committable={true}
                  className="mb--xxl"
                  activities={activities}
                  header={
                    <div className="mb--s">
                      <h4>
                        <span className="mr">
                          <MilestoneStatusIcon metadata={milestone.metadata} />
                        </span>
                        {milestone.name}
                      </h4>
                      <div className="mt--s flx flx--gap font-size--sm ">
                        <div className="flx flx--ai-center">
                          <EndingPointIcon className="mr--xs txt--secondary font-size--lg" />
                          <DisplayDate date={milestone.deadlineAt} />
                        </div>
                        {milestone.assignedTo[0] && (
                          <div className="flx flx--ai-center">
                            <PersonShieldIcon className="mr--xs txt--secondary font-size--lg" />
                            {friendlyUsername(milestone.assignedTo[0]?.data)}
                          </div>
                        )}
                        <div className="flx flx--ai-center">
                          <TextAreaIconIcon className="mr--xs txt--secondary font-size--default" />
                          <div className="ellipsis " style={{ maxWidth: 600 }}>
                            {milestone.description}
                          </div>
                        </div>
                        <Btn
                          size="small"
                          type="link"
                          className="ml--auto"
                          onClick={() =>
                            openModal({
                              type: 'createActivity',
                              teamId,
                              formType: {
                                type: 'simple',
                                prefilledValues: {
                                  supportsInitiativeId: group.initiative.id,
                                  supportsMilestoneId: milestone.id,
                                },
                              },
                            })
                          }
                        >
                          + Add Activity
                        </Btn>
                      </div>
                    </div>
                  }
                />
              )
            )}

            {/* Activities without specific milestone in this initiative */}
            {group.activitiesWithoutMilestone.length > 0 && (
              <ActivityTable
                teamId={teamId}
                committable={true}
                className="mb--l"
                activities={group.activitiesWithoutMilestone}
                header={
                  // Use simple header node - Corrected JSX
                  <div className="flx mb--s">
                    <h4>
                      Activities in this initiative not connected to Milestone
                    </h4>
                    <div className="flx--1 flx flx--jc-flx-end">
                      <Btn
                        size="small"
                        type="link"
                        className="ml--auto"
                        onClick={() =>
                          openModal({
                            type: 'createActivity',
                            teamId,
                            formType: {
                              // Use simple form type
                              type: 'simple',
                              prefilledValues: {
                                supportsInitiativeId: group.initiative.id,
                                // No milestone prefill here
                              },
                            },
                          })
                        }
                      >
                        + Add Activity
                      </Btn>
                    </div>
                  </div>
                }
              />
            )}
          </div>
        ))}

        {/* Activities without any connection */}
        {activitiesWithoutConnection.length > 0 && (
          <ActivityTable
            teamId={teamId}
            committable={true}
            className="mt--xl mb--xl"
            activities={activitiesWithoutConnection}
            header={
              // Use simple header node - Corrected JSX
              <div className="flx mb--s">
                <h3>
                  Activities without connection to any Initiative or Milestone
                </h3>
                <div className="flx--1 flx flx--jc-flx-end">
                  <Btn
                    size="small"
                    type="link"
                    className="ml--auto"
                    onClick={() =>
                      openModal({ type: 'createActivity', teamId })
                    }
                  >
                    + Add Activity
                  </Btn>
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

// Define the structure for grouped activities using specific fragment types
interface InitiativeGroup {
  initiative: InitiativeCommitmentDataFragment;
  milestones: Map<
    string,
    {
      milestone: MilestoneCommitmentDataFragment;
      activities: InitiativeBasedCommitments_MitemFragment[];
    }
  >;
  activitiesWithoutMilestone: InitiativeBasedCommitments_MitemFragment[];
}

/**
 * Groups activities by the initiatives they support (directly or via milestones).
 * @param activities - The list of activities (already filtered for completion status).
 * @returns An object containing the map of grouped initiatives and a list of unconnected activities.
 */
const groupActivitiesByInitiative = (
  activities: InitiativeBasedCommitments_MitemFragment[]
): {
  initiativeMap: Map<string, InitiativeGroup>;
  unconnected: InitiativeBasedCommitments_MitemFragment[];
} => {
  const initiativeMap = new Map<string, InitiativeGroup>();
  const unconnected: InitiativeBasedCommitments_MitemFragment[] = [];

  activities.forEach((activity) => {
    const directlySupportedInitiatives = activity.supportsInitiativeLinks ?? [];
    const supportedMilestones = activity.supportsMilestoneLinks ?? [];
    let supportsInitiative = false;

    // Process direct initiative links
    directlySupportedInitiatives.forEach((link) => {
      if (!link?.data) return;
      supportsInitiative = true;
      const initiativeId = link.data.id;
      // Ensure initiative exists in the map
      if (!initiativeMap.has(initiativeId)) {
        initiativeMap.set(initiativeId, {
          initiative: link.data,
          milestones: new Map(),
          activitiesWithoutMilestone: [],
        });
      }
      // Add activity to the 'without milestone' list for this initiative (will be refined later)
      initiativeMap
        .get(initiativeId)
        ?.activitiesWithoutMilestone.push(activity);
    });

    // Process milestone links
    supportedMilestones.forEach((msLink) => {
      if (!msLink?.data?.metadata?.supportsInitiatives) return;
      const milestone = msLink.data;
      const milestoneId = milestone.id;
      const initiativesForMilestone =
        milestone.metadata.supportsInitiatives ?? [];

      initiativesForMilestone.forEach((initLink) => {
        if (!initLink?.data) return;
        supportsInitiative = true;
        const initiativeId = initLink.data.id;
        const initiativeData = initLink.data;

        // Ensure initiative exists in the map
        if (!initiativeMap.has(initiativeId)) {
          initiativeMap.set(initiativeId, {
            initiative: initiativeData,
            milestones: new Map(),
            activitiesWithoutMilestone: [],
          });
        }

        const initiativeGroup = initiativeMap.get(initiativeId)!;

        // Ensure milestone exists within the initiative group
        if (!initiativeGroup.milestones.has(milestoneId)) {
          initiativeGroup.milestones.set(milestoneId, {
            milestone: milestone,
            activities: [],
          });
        }
        // Add activity to the milestone's activity list
        initiativeGroup.milestones.get(milestoneId)?.activities.push(activity);

        // Check if this activity was previously added to 'activitiesWithoutMilestone'
        const index = initiativeGroup.activitiesWithoutMilestone.findIndex(
          (a) => a.id === activity.id
        );
        if (index > -1) {
          // Determine if the activity is *only* linked via this specific milestone within this initiative
          const otherMilestoneLinksInInitiative = supportedMilestones.filter(
            (ml) =>
              ml?.data?.metadata?.supportsInitiatives.some(
                (si) => si?.data?.id === initiativeId
              ) && ml?.data?.id !== milestoneId
          );
          const directLinksToThisInitiative =
            directlySupportedInitiatives.filter(
              (dl) => dl?.data?.id === initiativeId
            );

          // If not linked directly or via another milestone *in this initiative*, remove from 'without milestone' list
          if (
            otherMilestoneLinksInInitiative.length === 0 &&
            directLinksToThisInitiative.length === 0
          ) {
            initiativeGroup.activitiesWithoutMilestone.splice(index, 1);
          }
        }
      });
    });

    // If activity doesn't support any initiative directly or via milestone, add to unconnected list
    if (!supportsInitiative) {
      unconnected.push(activity);
    }
  });

  // Refine activitiesWithoutMilestone: Ensure activities are unique and correctly placed.
  // An activity should be here if it's linked directly to *this* initiative OR
  // linked via a milestone of *another* initiative, BUT NOT linked via a milestone of *this* initiative.
  initiativeMap.forEach((group) => {
    group.activitiesWithoutMilestone = getUnique(
      group.activitiesWithoutMilestone.filter((activity) => {
        const hasDirectLinkToThisInitiative =
          activity.supportsInitiativeLinks?.some(
            (l) => l?.data?.id === group.initiative.id
          );
        const linkedViaOtherInitiativeMilestone =
          activity.supportsMilestoneLinks?.some(
            (ml) =>
              !ml?.data?.metadata?.supportsInitiatives.some(
                (si) => si?.data?.id === group.initiative.id
              )
          );
        const linkedViaThisInitiativeMilestone =
          activity.supportsMilestoneLinks?.some((ml) =>
            ml?.data?.metadata?.supportsInitiatives.some(
              (si) => si?.data?.id === group.initiative.id
            )
          );

        return (
          (hasDirectLinkToThisInitiative ||
            linkedViaOtherInitiativeMilestone) &&
          !linkedViaThisInitiativeMilestone
        );
      }),
      (a) => a.id
    );
  });

  return { initiativeMap, unconnected };
};

/**
 * Sorts the grouped initiatives, their milestones, and associated activities.
 * @param initiativeMap - The map of grouped initiatives.
 * @param unconnected - The list of unconnected activities.
 * @returns An object containing the sorted array of initiative groups and the sorted list of unconnected activities.
 */
const sortGroupedAndUnconnectedActivities = (
  initiativeMap: Map<string, InitiativeGroup>,
  unconnected: InitiativeBasedCommitments_MitemFragment[]
): {
  groupedByInitiative: InitiativeGroup[];
  activitiesWithoutConnection: InitiativeBasedCommitments_MitemFragment[];
} => {
  // Sort initiatives alphabetically
  const sortedInitiatives = Array.from(initiativeMap.values()).sort((a, b) =>
    a.initiative.name.localeCompare(b.initiative.name)
  );

  // Sort milestones and activities within each initiative
  sortedInitiatives.forEach((group) => {
    // Sort milestones alphabetically
    group.milestones = new Map(
      [...group.milestones.entries()].sort((a, b) =>
        a[1].milestone.name.localeCompare(b[1].milestone.name)
      )
    );
    // Sort activities within milestones by deadline
    group.milestones.forEach((msGroup) => {
      msGroup.activities.sort((actA, actB) =>
        dayjs(actA.deadline).diff(dayjs(actB.deadline))
      );
    });
    // Sort activities without milestones by deadline
    group.activitiesWithoutMilestone.sort((actA, actB) =>
      dayjs(actA.deadline).diff(dayjs(actB.deadline))
    );
  });

  // Sort unconnected activities by deadline
  unconnected.sort((actA, actB) =>
    dayjs(actA.deadline).diff(dayjs(actB.deadline))
  );

  return {
    groupedByInitiative: sortedInitiatives,
    activitiesWithoutConnection: unconnected,
  };
};

// Adapting the fragment from EditActivityForm_Mitem to include necessary fields
// Ensure ActivityTable_Mitem fragment is imported/available if needed by ActivityTable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_COMMITMENT_ITEM_FRAGMENT = gql`
  fragment InitiativeBasedCommitments_Mitem on Mitem {
    id
    name
    deadline
    status
    completed # Add completed field
    archived
    owner2 {
      id
      name
      email
      initials
      displayName
      domainId {
        itemId
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        ...InitiativeCommitmentData
      }
    }
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        ...MilestoneCommitmentData
      }
    }
    ...ActivityTable_Mitem # Include fragment needed by ActivityTable
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_COMMITMENT_DATA_FRAGMENT = gql`
  fragment InitiativeCommitmentData on InitiativeWithLinks {
    id
    name
    description
    tag {
      colorCode
      iconId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_COMMITMENT_DATA_FRAGMENT = gql`
  fragment MilestoneCommitmentData on MilestoneWithLinks {
    id
    name
    deadlineAt
    description
    assignedTo {
      id
      data {
        id
        name
        email
        initials
        displayName
      }
    }
    metadata {
      status
      archived
      supportsInitiatives {
        id
        domainId {
          itemId
        }
        data {
          ...InitiativeCommitmentData # Reuse initiative data fragment
        }
      }
    }
  }
`;

// Query to fetch activities, similar to MonthBasedCommitments but without date range initially
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_ACTIVITIES_QUERY = gql`
  query GetActivitiesForInitiativeBasedCommitmentStep(
    $teamId: ID!
    $archived: Boolean # Optional: Add if needed to filter archived
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived # Remove startDate and endDate unless needed for specific filtering
    ) {
      sprintKeyActivities {
        id
        ...InitiativeBasedCommitments_Mitem
      }
    }
  }
`;
